import React, { Component } from "react";
import { Main_Color } from "../../../Theme/Theme";
import { AiFillDelete, AiFillCaretDown } from "react-icons/ai";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import Backend from "../../../Backend/Backend";
const backend = new Backend();
export default class SalesCard extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      loading: false,
    };
  }

  deleteBill(id) {
    confirmAlert({
      title: "Confirm to Delete Bill",
      message: "Are you sure You want to delete bill.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.deleteBill_final(id);
          },
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  }

  deleteBill_final(id) {
    this.setState({ loading: true });

    let data = {
      token: localStorage.getItem("token"),
      adminid: localStorage.getItem("adminid"),
    };

    backend
      .delete_bill(data, id)
      .then((r) => {
        this.loadconent();
      })
      .catch((err) => {
        this.loadconent();
        this.setState({ loading: false });
      });
  }

  render() {
    const i = this.props.data;
    console.log(i);
    return (
      <>
        <div
          style={{
            display: "flex",
            flex: 1,
            backgroundColor: "#ffffff",
            justifyContent: "space-between",
            alignItems: "center",
            // borderRadius: 10,
            borderRadius: this.state.show == true ? 0 : 10,
            padding: 10,
            marginTop: 10,
          }}
        >
          <div
            style={{
              paddingLeft: 20,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ paddingRight: 20, color: "#585858" }}>{i.billno}</div>
            <div>
              <div
                style={{
                  color: "#000000",
                  fontWeight: "bold",
                  fontSize: 18,
                }}
              >
                {i.table_name} - {i.workername}
              </div>
              <div
                style={{
                  color: "#000000",
                  fontWeight: "bold",
                  marginTop: 2,
                }}
              >
                Total - {i.total} | Discount - {i.discount}
              </div>
              <div
                style={{
                  color: "#000000",
                  fontWeight: "bold",
                  marginTop: 2,
                }}
              >
                SGST - {i.sgst} | CGST - {i.cgst}
              </div>

              <div
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  marginTop: 2,
                  display: "flex",
                }}
              >
                Date - {i.invoice_date}
              </div>
              <div
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  marginTop: 2,
                  display: "flex",
                }}
              >
                Name - {i.customer_name}
              </div>
              <div
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  marginTop: 2,
                  display: "flex",
                }}
              >
                Mobile - {i.customer_mobile}
              </div>
              <div
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  marginTop: 2,
                  display: "flex",
                }}
              >
                Details - {i.customer_address}
              </div>
            </div>
          </div>
          <div
            style={{
              paddingLeft: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ color: "#000000", fontWeight: "bold" }}>
                REGISTER ID - {i.register_id} | WORKER ID - {i.worker_id}
              </div>
              <div
                style={{ color: "#000000", fontWeight: "bold", marginTop: 5 }}
              >
                PAYMENT - {i.payment_method}
              </div>
              <div
                style={{
                  color: Main_Color,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 22,
                }}
              >
                NET AMOUNT - {i.net_amount}
              </div>
            </div>
          </div>
          <div
            style={{
              paddingLeft: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {i.status == 0 ? (
                <div
                  style={{
                    color: "green",
                    fontWeight: "bold",
                    padding: 10,
                    marginRight: 10,
                  }}
                >
                  <img
                    src={require("../../../Image/delete.png")}
                    style={{ height: 50 }}
                  />
                </div>
              ) : (
                <div
                  onClick={() => this.deleteBill(i.id)}
                  style={{
                    color: "green",
                    fontWeight: "bold",
                    padding: 10,
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    marginRight: 10,
                  }}
                >
                  <AiFillDelete style={{ fontSize: 25, color: Main_Color }} />
                </div>
              )}

              <div
                onClick={() => this.setState({ show: !this.state.show })}
                style={{
                  color: "green",
                  fontWeight: "bold",
                  padding: 10,
                  backgroundColor: "#f2f2f2",
                  borderRadius: 10,
                  marginRight: 10,
                }}
              >
                <AiFillCaretDown style={{ fontSize: 25, color: "green" }} />
              </div>
            </div>
          </div>
        </div>
        {this.state.show == true ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              backgroundColor: "#ffffff",
              justifyContent: "center",
              flexDirection: "column",
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                backgroundColor: "#ffffff",
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              <div style={{ flex: 1 }}>Product Name</div>
              <div style={{ flex: 1 }}>Rate</div>
              <div style={{ flex: 1 }}>Qty</div>
              <div style={{ flex: 1 }}>Amount</div>
            </div>
            {i.items.map((i2, t2) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    padding: 10,
                    paddingLeft: 20,
                    paddingRight: 20,
                    backgroundColor: "#EAF0FE",
                    borderStyle: "solid",
                    borderWidth: 0,
                    borderBottomWidth: 2,
                    borderBottomColor: "#ffffff",
                    borderRadius: 10,
                  }}
                >
                  <div style={{ flex: 1 }}>{i2.item_name}</div>
                  <div style={{ flex: 1 }}>{i2.rate}</div>
                  <div style={{ flex: 1 }}>{i2.qty}</div>
                  <div style={{ flex: 1 }}>{i2.amt}</div>
                </div>
              );
            })}
          </div>
        ) : null}
      </>
    );
  }
}
