import React, { useEffect, useState } from "react";
import "./Edit.css";
import { InputText } from "primereact/inputtext";
import { Main_Color } from "./../../../Theme/Theme";
import { AiFillCloseCircle } from "react-icons/ai";
import Backend from "../../../Backend/Backend";
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
const backend = new Backend();
const Edit = (props) => {
  const [name, setName] = useState(props.data.name);
  const [unit_id, setUnit_id] = useState(props.data.unit_id);
  const [unit, setUnit] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = useState(localStorage.getItem("adminid"));
  const [alert_type, setAlert_type] = useState(localStorage.getItem("adminid"));
  const [loading, setLoading] = useState(false);

  const alert = useAlert();

  useEffect(() => {
    loadunit();
  }, []);

  const loadunit = () => {
    setLoading(true);
    var data = {
      token: token,
      adminid: adminid,
      raw_name: name,
      unit_id: unit_id,
    };

    backend.load_unit(data).then((r) => {
      let arrayObj = r.data;
      arrayObj = arrayObj.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });

      if (r.error == "False") {
        setUnit(arrayObj);
        setLoading(false);
      }
    });
  };

  const _edit_rawitems = () => {
    if (name === "") {
      setAlert_type(1);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else {
      let data = {
        raw_name: name,
        token: token,
        adminid: adminid,
        unit_id: unit_id,
        raw_id: props.data.id,
      };
      setLoading(true);
      backend.edit_raw(data).then((r2) => {
        setLoading(false);
        if (r2.error === "False") {
          props.close();
        } else {
          alert.show(r2.message);
        }
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <div
        style={{
          height: "60%",
          width: "40%",
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          display: "flex",
          alignSelf: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 20,
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: Main_Color,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>Edit Raw Item</div>
          <div onClick={() => props.close()}>
            <AiFillCloseCircle style={{ height: "40px", width: "40px" }} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span
                className="p-float-label"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <InputText
                  placeholder="Rew-Item Name "
                  style={{
                    width: "500px",
                    marginTop: 10,
                    borderWidth: alert_type === 1 ? 2 : 1,
                    borderColor: alert_type === 1 ? Main_Color : "",
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {alert_type === 1 ? (
                  <div
                    style={{ marginTop: 5, paddingLeft: 10, color: Main_Color }}
                  >
                    {"Invalid Rew Item Name "}
                  </div>
                ) : null}
              </span>
            </div>

            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span
                className="p-float-label"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Dropdown
                  value={unit_id}
                  options={unit}
                  onChange={(e) => setUnit_id(e.value)}
                  placeholder="Select a Unit"
                />
              </span>
            </div>

            {loading === true ? (
              <div
                style={{
                  alignSelf: "center",
                  marginTop: 40,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                <ReactLoading
                  type={"bars"}
                  color={"#fff"}
                  height={45}
                  width={45}
                />
              </div>
            ) : (
              <div
                onClick={_edit_rawitems}
                style={{
                  alignSelf: "center",
                  marginTop: 40,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                Submitt
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
