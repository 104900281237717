import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Main_Color } from "../../Theme/Theme";
import ReactLoading from "react-loading";
import Backend from "../../Backend/Backend";

const backend = new Backend();

export default class Addess extends Component {
  constructor() {
    super();
    this.state = {
      admindata: JSON.parse(localStorage.getItem("admindata")),
      name: "",
      logourl: "",
      company_name: "",
      mobile: "",
      email: "",
      alternate_mobile: "",
      id_type: "",
      idnumber: "",
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      state: "",
      country: "",
      gst_active: false,
      gst_no: "",
      sgst: "",
      cgst: "",
      birth_date: "",
      pdf_password: "",
      loading: true,
    };
  }

  componentDidMount() {
    this.load_profile();
  }

  load_profile() {
    var data = {
      authtoken: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
    };
    backend.load_profile(data).then((r2) => {
      this.setState({ loading: false });
      if (r2.error == "False") {
        this.setState({
          name: r2.data.name,
          logourl: r2.data.logourl,
          company_name: r2.data.company_name,
          mobile: r2.data.mobile,
          email: r2.data.email,
          alternate_mobile: r2.data.alternate_mobile,
          id_type: r2.data.id_type,
          idnumber: r2.data.idnumber,
          address_line_1: r2.data.split[0],
          address_line_2: r2.data.split[1],
          address_line_3: r2.data.split[2],
          state: r2.data.state,
          country: r2.data.country,
          gst_active: r2.data.gst_active,
          gst_no: r2.data.gst_no,
          sgst: r2.data.sgst,
          cgst: r2.data.cgst,
          birth_date: r2.data.birth_date,
          pdf_password: r2.data.pdf_password,
          loading: false,
        });
      }
    });
  }

  edit_profile() {
    this.setState({ loading: true });
    var data = {
      authtoken: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
    };
    var profiledetails = {
      name: this.state.name,
      company_name: this.state.company_name,
      cgst: this.state.gst_active == 1 ? this.state.cgst : 0,
      sgst: this.state.gst_active == 1 ? this.state.sgst : 0,
      email: this.state.email,
      alternate_mobile: this.state.alternate_mobile,
      state: this.state.state,
      country: this.state.country,
      gst_no: this.state.gst_no,
      pdf_password: this.state.pdf_password,
      gst_active: this.state.gst_active,
    };
    var addresss = [
      this.state.address_line_1,
      this.state.address_line_2,
      this.state.address_line_3,
    ];
    backend.update_profile(data, profiledetails, addresss).then((r2) => {
      if (r2 == "false") {
        this.load_profile();
      } else {
        this.load_profile();
      }
    });
  }

  render() {
    return (
      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 40,
            paddingLeft: 10,
            fontSize: 30,
            flex: 1,
            paddingBottom: 0,
            marginTop: 50,
          }}
        >
          Addess Setting
        </div>
        <div style={{ marginTop: 50, display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              paddingRight: 30,
            }}
          >
            <label htmlFor="in" style={{ marginBottom: 10 }}>
              Address Line 1
            </label>
            <InputText
              id="in"
              value={this.state.address_line_1}
              placeholder="Enter Address Line 1"
              onChange={(e) =>
                this.setState({ address_line_1: e.target.value })
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              paddingRight: 30,
            }}
          >
            <label htmlFor="in" style={{ marginBottom: 10 }}>
              Address Line 2
            </label>
            <InputText
              id="in"
              placeholder="Enter Address Line 2"
              value={this.state.address_line_2}
              onChange={(e) =>
                this.setState({ address_line_2: e.target.value })
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              paddingRight: 30,
            }}
          >
            <label htmlFor="in" style={{ marginBottom: 10 }}>
              Address Line 3
            </label>
            <InputText
              id="in"
              placeholder="Enter Address Line 3"
              value={this.state.address_line_3}
              onChange={(e) =>
                this.setState({ address_line_3: e.target.value })
              }
            />
          </div>
        </div>

        <div style={{ marginTop: 50, display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              paddingRight: 30,
            }}
          >
            <label htmlFor="in" style={{ marginBottom: 10 }}>
              Print Your End Msg With 5-6 Word
            </label>
            <InputText
              id="in"
              value={this.state.print_msg}
              placeholder="Enter Your Msg "
              onChange={(e) => this.setState({ print_msg: e.target.value })}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 50,
          }}
        >
          {this.state.loading == true ? (
            <div
              style={{
                height: "50px",
                width: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Main_Color,
                borderRadius: 10,
                color: "#ffffff",
              }}
            >
              <ReactLoading
                type={"bars"}
                color={"#ffffff"}
                height={40}
                width={40}
              />
            </div>
          ) : (
            <div
              onClick={() => this.edit_profile()}
              style={{
                height: "50px",
                width: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Main_Color,
                borderRadius: 10,
                color: "#ffffff",
              }}
            >
              Update Profile
            </div>
          )}
        </div>
      </div>
    );
  }
}
