import React, { Component, useEffect } from "react";

import Variant from "./Variant/Variant";
import Product from "./Product";
import Recipe from "./Recipe/Recipe";

const Index = () => {
  const [variant, setVariant] = React.useState(false);
  const [recipe, setRecipe] = React.useState(false);
  const [product, setProduct] = React.useState(false);
  const [data, setData] = React.useState("");

  useEffect(() => {
    setProduct(true);
  }, []);

  const open_variant = () => {
    setProduct(false);
    setRecipe(false);
    setVariant(true);
  };
  const open_recipe = () => {
    setProduct(false);
    setVariant(false);
    setRecipe(true);
  };

  const close_variant = () => {
    setProduct(true);
    setRecipe(false);
    setVariant(false);
  };

  const close_recipe = () => {
    setProduct(true);
    setVariant(false);
    setRecipe(false);
  };

  const passdata = (r) => {
    setData(r);
  };

  return (
    <div style={{ flex: 1, backgroundColor: "#f2f2f2" }}>
      {variant == true ? (
        <Variant data={data} close_variant={() => close_variant()} />
      ) : null}
      {recipe == true ? (
        <Recipe data={data} close_variant={() => close_recipe()} />
      ) : null}
      {product == true ? (
        <Product
          open_variant={() => open_variant()}
          open_recipe={() => open_recipe()}
          passdata={(r) => passdata(r)}
        />
      ) : null}
    </div>
  );
};

export default Index;
