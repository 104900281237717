import * as React from "react";
import { useAlert } from "react-alert";
import Modal from "@mui/material/Modal";
import Add from "./Add/Add";
import Edit from "./Edit/Edit";
import { Main_Color } from "../../Theme/Theme";
import { BsPlusSquareFill } from "react-icons/bs";
import { FcManager } from "react-icons/fc";
import CaptainCard from "./CaptainCard";
import Backend from "../../Backend/Backend";
import { AiFillCloseSquare } from "react-icons/ai";
import { InputText } from "primereact/inputtext";
import hotkeys from "hotkeys-js";
const backend = new Backend();
export default function Captain() {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [data_worker, setData_worker] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = React.useState(localStorage.getItem("adminid"));
  const [edit_modal, setEdit_modal] = React.useState(false);
  const [editdata, setEditData] = React.useState("");
  const [search, setSearch] = React.useState("");
  const alert = useAlert();
  const input1 = React.useRef(null);

  React.useEffect(() => {
    hotkeys("esc", async (event, handler) => {
      event.preventDefault();
      handleClose();
      handleClose_edit();
    });
    hotkeys("s", async (event, handler) => {
      event.preventDefault();
      input1.current.focus();
    });

    loadconent();
    return () => {
      hotkeys.unbind();
    };
  }, []);

  const loadconent = () => {
    setLoading(true);
    var data = {
      token: token,
      adminid: adminid,
    };
    backend.workerlist(data).then((r) => {
      if (r.error === "False") {
        setData(r.data);
        setData_worker(r.data);
        setLoading(false);
      } else {
        alert.error("You just broke something!");
        setLoading(false);
      }
    });
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleOpen_edit = (i) => {
    setEditData(i);
    setEdit_modal(true);
  };

  const handleClose_edit = () => {
    setEdit_modal(false);
    setEditData("");
  };

  const onSearch = (e) => {
    setSearch(e);
    if (e.length > 1) {
      let filteredData = [];
      for (var i = 0; i < data_worker.length; i++) {
        if (data_worker[i].name.toLowerCase().includes(e.toLowerCase())) {
          filteredData.push(data_worker[i]);
        }
      }
      setData(filteredData);
    } else {
      setData(data_worker);
    }
  };

  return (
    <div style={{ flex: 1, backgroundColor: "#f2f2f2" }}>
      <div
        style={{
          display: "flex",
          height: "100px",
          backgroundColor: "#ffffff",
          flex: 1,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: 20,
            color: Main_Color,
            fontSize: 22,
            paddingLeft: 40,
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <FcManager style={{ fontSize: 55 }} />
          <div style={{ paddingLeft: 20, fontSize: 22, flex: 1 }}>Captain</div>
          <div
            style={{
              paddingLeft: 20,
              fontSize: 22,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InputText
              ref={input1}
              placeholder="Captain Search...."
              value={search}
              style={{
                width: "300px",
                marginTop: 10,
                borderRadius: 0,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
              onChange={(e) => onSearch(e.target.value)}
            />

            <div
              onClick={() => {
                setSearch("");
                loadconent();
              }}
              style={{
                height: "45px",
                backgroundColor: Main_Color,
                marginTop: 10,
                width: "45px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              <AiFillCloseSquare
                style={{
                  color: "#ffffff",
                  height: 40,
                  width: 40,
                }}
              />
            </div>
          </div>
        </div>
        <div
          onClick={handleOpen}
          style={{
            display: "flex",
            padding: 20,
            color: Main_Color,
            fontSize: 22,
            paddingRight: 40,
          }}
        >
          <BsPlusSquareFill style={{ fontSize: 40 }} />
        </div>
      </div>
      <div style={{ padding: 10 }}>
        <div
          style={{
            flex: 1,
            height: "83vh",
            borderRadius: 10,
            overflowY: "scroll",
          }}
        >
          {loading === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#ffffff",
                height: "100%",
              }}
            >
              <img src={require("../../Image/loading.gif")} />
            </div>
          ) : (
            data.map((i, t) => {
              return (
                <CaptainCard
                  item={i}
                  index={t}
                  edit_modal={(i) => handleOpen_edit(i)}
                  loadNew={() => loadconent()}
                />
              );
            })
          )}
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Add
          close={() => {
            loadconent();
            handleClose(false);
          }}
        />
      </Modal>
      <Modal
        open={edit_modal}
        onClose={handleClose_edit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Edit
          close={() => {
            loadconent();
            handleClose_edit(false);
          }}
          data={editdata}
        />
      </Modal>
    </div>
  );
}
