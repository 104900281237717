import React, { Component, useRef } from "react";
import { BsPlusSquareFill } from "react-icons/bs";
import { FcManager } from "react-icons/fc";
import { Main_Color } from "../../Theme/Theme";
import Backend from "../../Backend/Backend";
import { useAlert } from "react-alert";
import Modal from "@mui/material/Modal";
import ProductCard from "./ProductCard";
import Add from "./Add/Add";
import Edit from "./Edit/Edit";
import Stock from "./Stock/Stock";
import { InputText } from "primereact/inputtext";
import { AiFillCloseSquare } from "react-icons/ai";
import * as XLSX from "xlsx";
import hotkeys from "hotkeys-js";

const backend = new Backend();
const Product = (props) => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [data_search, setData_Search] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = React.useState(localStorage.getItem("adminid"));
  const [edit_modal, setEdit_modal] = React.useState(false);
  const [editdata, setEditData] = React.useState("");
  const [stockdata, setStock] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const alert = useAlert();

  const input1 = useRef(null);

  React.useEffect(() => {
    hotkeys("esc", async (event, handler) => {
      event.preventDefault();
      handleClose();
      handleClose_edit();
    });
    hotkeys("s", async (event, handler) => {
      event.preventDefault();
      input1.current.focus();
    });
    loadconent();
  }, []);

  const loadconent = () => {
    setLoading(true);
    var data = {
      token: token,
      adminid: adminid,
    };
    backend.productlist(data).then((r) => {
      if (r.error === "False") {
        setData(r.data);
        setData_Search(r.data);
        setLoading(false);
      } else {
        alert.error("You just broke something!");
        setLoading(false);
      }
    });
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleOpen_edit = (i) => {
    setEditData(i);
    setEdit_modal(true);
  };
  const handleClose_edit = () => {
    setEdit_modal(false);
    setEditData("");
  };
  const handleOpen_stock = (i) => {
    setEditData(i);
    setStock(true);
  };

  const handleClose_Stock = () => {
    setStock(false);
    setEditData("");
  };

  const onSearch = (e) => {
    setSearch(e);
    if (e.length > 1) {
      let filteredData = [];
      for (var i = 0; i < data_search.length; i++) {
        if (
          data_search[i].product_name.toLowerCase().includes(e.toLowerCase())
        ) {
          filteredData.push(data_search[i]);
        } else if (data_search[i].default_price == e) {
          filteredData.push(data_search[i]);
        } else if (
          data_search[i].alias.toLowerCase().includes(e.toLowerCase())
        ) {
          filteredData.push(data_search[i]);
        }
      }

      setData(filteredData);
    } else {
      setData(data_search);
    }
  };

  const Excel_donwload = () => {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, "Product");

    XLSX.writeFile(wb, "Product.xlsx");
  };

  return (
    <div style={{ flex: 1, backgroundColor: "#f2f2f2" }}>
      <div
        style={{
          display: "flex",
          height: "100px",
          backgroundColor: "#ffffff",
          flex: 1,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: 20,
            color: Main_Color,
            fontSize: 22,
            paddingLeft: 40,
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <FcManager
            onClick={() => Excel_donwload()}
            style={{ fontSize: 55 }}
          />
          <div style={{ paddingLeft: 20, fontSize: 22, flex: 1 }}>Product</div>
          <div
            style={{
              paddingLeft: 20,
              fontSize: 22,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InputText
              placeholder="Product Search...."
              value={search}
              ref={input1}
              style={{
                width: "300px",
                marginTop: 10,
                borderRadius: 0,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
              onChange={(e) => onSearch(e.target.value)}
            />

            <div
              onClick={() => {
                setSearch("");
                loadconent();
              }}
              style={{
                height: "45px",
                backgroundColor: Main_Color,
                marginTop: 10,
                width: "45px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              <AiFillCloseSquare
                style={{
                  color: "#ffffff",
                  height: 40,
                  width: 40,
                }}
              />
            </div>
          </div>
        </div>
        <div
          onClick={handleOpen}
          style={{
            display: "flex",
            padding: 20,
            color: Main_Color,
            fontSize: 22,
            paddingRight: 40,
          }}
        >
          <BsPlusSquareFill style={{ fontSize: 40 }} />
        </div>
      </div>

      <div style={{ padding: 10 }}>
        <div
          style={{
            flex: 1,
            height: "83vh",
            borderRadius: 10,
            overflowY: "scroll",
          }}
        >
          {loading === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#ffffff",
                height: "100%",
              }}
            >
              <img src={require("../../Image/loading.gif")} />
            </div>
          ) : data.length > 0 ? (
            data.map((i, t) => {
              return (
                <ProductCard
                  item={i}
                  index={t}
                  open_variant={() => props.open_variant()}
                  open_recipe={() => props.open_recipe()}
                  passdata={(r) => props.passdata(r)}
                  edit_modal={(i) => handleOpen_edit(i)}
                  add_stock_modal={(i) => handleOpen_stock(i)}
                  loadNew={() => loadconent()}
                />
              );
            })
          ) : (
            <div
              style={{
                flex: 1,
                marginTop: 10,
                backgroundColor: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                borderRadius: 10,
              }}
            >
              <img
                src={require("../../Image/noData.gif")}
                style={{ height: "50vh" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "bold", color: "gray" }}>
                  NO DATA FOUND
                </div>
                <div
                  onClick={() => loadconent()}
                  style={{
                    fontWeight: "bold",
                    color: "gray",
                    marginTop: 20,
                    padding: 15,
                    backgroundColor: Main_Color,
                    borderRadius: 10,
                    color: "#fff",
                  }}
                >
                  RELOAD
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Add
          close={() => {
            loadconent();
            handleClose(false);
          }}
        />
      </Modal>
      <Modal
        open={edit_modal}
        onClose={handleClose_edit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Edit
          close={() => {
            loadconent();
            handleClose_edit(false);
          }}
          data={editdata}
        />
      </Modal>
      <Modal
        open={stockdata}
        onClose={handleClose_Stock}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stock
          close={() => {
            loadconent();
            handleClose_Stock(false);
          }}
          data={editdata}
        />
      </Modal>
    </div>
  );
};

export default Product;
