const api_link = "http://apis.postick.co.in/admin_apir.php";
// const api_link_node = "http://shreeramtech.com:4000";
const access_key = "123";
const access_key_node = "1234";
export default class Backend {
  login(user) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        admin_login: "1",
        access_key: access_key,
        mobile: user.mobile,
        password: user.password,
      }),
    }).then((response) => response.json());
  }

  workerlist(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        worker_list: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  addworker(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_worker: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        name: data.name,
        mobile: data.mobile,
        password: data.password,
        status: 1,
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_variant(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_variant: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        variant_id: data.variant_id,
      }),
    }).then((response) => response.json());
  }

  update_variant(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        update_variant: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  editworker(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_worker: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        name: data.name,
        worker_id: data.worker_id,
        mobile: data.mobile,
        password: data.password,
        data: data,
        resetbill: data.reset_bill,
        status: data.status,
        kitchen_user: data.kitchen,
      }),
    }).then((response) => response.json());
  }

  load_unit(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_unit: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  add_unit(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_unit: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  add_raw(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_raw: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  tableslist(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tables_list: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  load_raw_items(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_raw_items: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  edit_raw(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_raw: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_stock(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_stock: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_recipe(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_recipe: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  addtable(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_tables: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        name: data.table_name,
      }),
    }).then((response) => response.json());
  }

  load_recipe_items(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_recipe_items: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  add_raw_items(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_raw_items: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  update_recipe(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        update_recipe: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  delete_recipe_item(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        delete_recipe_item: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  edittables(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_tables: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        name: data.table_name,
        id: data.id,
      }),
    }).then((response) => response.json());
  }

  productlist(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product_list: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  catagorieslist(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        catagories_list: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  addcategory(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_category: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        name: data.catagory_name,
        status: 1,
      }),
    }).then((response) => response.json());
  }

  editcategory(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_category: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        name: data.catagory_name,
        id: data.id,
        status: data.status,
      }),
    }).then((response) => response.json());
  }

  addproduct(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_product: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        alias: data.alias,
        name: data.product_name,
        unicodename: data.unicode_name,
        defaultprice: data.price,
        price_ac: data.price_ac,
        catid: data.catagory_id,
        status: 1,
        fav: data.fav,
      }),
    }).then((response) => response.json());
  }

  editproduct(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_product: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        id: data.id,
        name: data.product_name,
        unicodename: data.unicode_name,
        defaultprice: data.price,
        price_ac: data.price_ac,
        catid: data.catagory_id,
        status: data.status,
        fav: data.fav,
        alias: data.alias,
        hasvarinty: data.hasvarinty,
      }),
    }).then((response) => response.json());
  }

  checkstore(data, token) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        check_store: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        pushtoken: token,
      }),
    }).then((response) => response.json());
  }

  active_deactive(data, token) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        active_deactive: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        storeid: data.storeid,
      }),
    }).then((response) => response.json());
  }

  register_store(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        register_store: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        company_name: data.company_name,
        mobile: data.mobile,
        address: data.address,
        cusines: data.cuisines,
        location: data.location_data,
      }),
    }).then((response) => response.json());
  }

  StoreMenuItems(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        store_menuitem: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        storeid: data.storeid,
      }),
    }).then((response) => response.json());
  }

  add_menu_item(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_menu_item: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        storeid: data.storeid,
        name: data.catagory_name,
        status: 1,
      }),
    }).then((response) => response.json());
  }

  add_variant(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_variant: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_menu_item(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_menu_item: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        name: data.catagory_name,
        storeid: data.storeid,
        id: data.id,
        status: data.status,
      }),
    }).then((response) => response.json());
  }

  productlist_online(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product_list_online: "1",
        access_key: access_key,
        admin_id: data.adminid,
        storeid: data.storeid,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  addproduct_online(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_product_online: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        name: data.product_name,
        storeid: data.storeid,
        product_detail: data.product_detail,
        defaultprice: data.price,
        catid: data.catagory_id,
        status: 1,
        vegnonveg: data.fav,
      }),
    }).then((response) => response.json());
  }

  editproduct_online(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_product_online: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        id: data.id,
        storeid: data.storeid,
        name: data.product_name,
        details: data.unicode_name,
        rate: data.price,
        menuid: data.catagory_id,
        status: data.status,
        vegnonveg: data.fav,
      }),
    }).then((response) => response.json());
  }

  registeruser(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        register_user2: "1",
        access_key: access_key,
        data: data,
      }),
    }).then((response) => response.json());
  }

  registerlist(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        register_list: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        workername: data.worker_id,
        datefrom: data.datefrom,
        dateto: data.dateto,
      }),
    }).then((response) => response.json());
  }

  delete_bill(data, id) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        delete_bill: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        bill_id: id,
      }),
    }).then((response) => response.json());
  }

  otpvarify(a, b) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        varify_otp: "1",
        access_key: access_key,
        mobile: a,
        otp: b,
      }),
    }).then((response) => response.json());
  }

  load_profile(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_profile: "1",
        access_key: access_key,
        admin_id: data.admin_id,
        authtoken: data.authtoken,
      }),
    }).then((response) => response.json());
  }

  update_profile(data, a, b, c) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        update_profile: "1",
        access_key: access_key,
        admin_id: data.admin_id,
        authtoken: data.authtoken,
        profiledetail: a,
        address: b,
        gst: c,
      }),
    }).then((response) => response.json());
  }

  forgot_password(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        forgot_password: "1",
        mobile: data.mobile,
        password: data.password,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  otpvarify2(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        varify_otp2: "1",
        access_key: access_key,
        mobile: data.mobile,
        otp: data.otp,
        password: data.password,
      }),
    }).then((response) => response.json());
  }

  salesreport(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sales_list_new: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        workername: data.worker_id,
        payment_id: data.payment_id,
        datefrom: data.datefrom,
        dateto: data.dateto,
      }),
    }).then((response) => response.json());
  }

  table_report(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        table_report: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        workername: data.worker_id,
        datefrom: data.fromdate,
        dateto: data.todate,
      }),
    }).then((response) => response.json());
  }

  swap_table_report(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        swap_table_report: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        workername: data.worker_id,
        datefrom: data.fromdate,
        dateto: data.todate,
      }),
    }).then((response) => response.json());
  }

  salesreport_monthly(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        salesreport_monthly: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        workername: data.worker_id,
        datefrom: data.fromdate,
        dateto: data.todate,
      }),
    }).then((response) => response.json());
  }

  table_report_monthly(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        table_report_monthly: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        workername: data.worker_id,
        datefrom: data.fromdate,
        dateto: data.todate,
      }),
    }).then((response) => response.json());
  }

  salesreport_online(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sales_list_online: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        datefrom: data.fromdate,
        dateto: data.todate,
      }),
    }).then((response) => response.json());
  }

  load_payment_methods(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_payment_method: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        datefrom: data.fromdate,
        dateto: data.todate,
      }),
    }).then((response) => response.json());
  }

  load_comment(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_comment: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  load_reasons(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_reason: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  add_payment(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_payment_method: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  add_reason(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_reason: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  add_comment(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_comment: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_payment(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_payment_method: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_reason(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_reason: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_comment(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_comment: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  homeapi(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        home_api: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  load_pending(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_pending: "1",
        access_key: access_key,
        storeid: data.storeid,
        admin_id: data.adminid,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  load_complete(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_complete: "1",
        access_key: access_key,
        storeid: data.storeid,
        admin_id: data.adminid,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  accept_order(data, order, type) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        accept_order: "1",
        access_key: access_key,
        storeid: data.storeid,
        admin_id: data.adminid,
        authtoken: data.token,
        order: order.id,
        type: type,
      }),
    }).then((response) => response.json());
  }

  itemsreport(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        items_list_new: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        workername: data.worker_id,
        datefrom: data.datefrom,
        dateto: data.dateto,
      }),
    }).then((response) => response.json());
  }

  itemsreport_online(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        itemsreport_online: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        datefrom: data.fromdate,
        dateto: data.todate,
      }),
    }).then((response) => response.json());
  }

  cleardevice(id, data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clear_device: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        worker_id: id,
      }),
    }).then((response) => response.json());
  }

  itemsreport_cat(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        items_list_category: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        workername: data.worker_id,
        datefrom: data.fromdate,
        dateto: data.todate,
      }),
    }).then((response) => response.json());
  }

  // reset_bill(data) {
  //   return fetch(api_link_node + "/reset", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       accesskey: access_key_node,
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then((response) => response.json());
  // }

  deleteworker(id, data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        delete_worker: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        worker_id: id,
      }),
    }).then((response) => response.json());
  }
  deleteproduct(id, data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        delete_product: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        product_id: id,
      }),
    }).then((response) => response.json());
  }
  deletecategorie(id, data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        delete_categorie: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        categorie_id: id,
      }),
    }).then((response) => response.json());
  }
  deletecomment(id, data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        delete_comment: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        comment_id: id,
      }),
    }).then((response) => response.json());
  }
  deletetable(id, data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        delete_table: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        table_id: id,
      }),
    }).then((response) => response.json());
  }
  deleterawitem(id, data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        delete_rawitem: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        rawitem_id: id,
      }),
    }).then((response) => response.json());
  }
  deletereason(id, data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        delete_reason: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        reason_id: id,
      }),
    }).then((response) => response.json());
  }
  deletepayment(id, data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        delete_payment: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        payment_id: id,
      }),
    }).then((response) => response.json());
  }
  addproductstock(id, data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_stock: "1",
        access_key: access_key,
        admin_id: data.adminid,
        authtoken: data.token,
        product_id: id,
        data: data,
      }),
    }).then((response) => response.json());
  }

  new_sequencer_categories(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        category_sequence: "1",
        admin_id: data.adminid,
        access_key: access_key,
        authtoken: data.token,
        newdata: data.newdata,
      }),
    }).then((response) => response.json());
  }
  new_sequencer_product(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product_sequence: "1",
        admin_id: data.adminid,
        access_key: access_key,
        authtoken: data.token,
        newdata: data.newdata,
      }),
    }).then((response) => response.json());
  }
  new_sequencer_table(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        table_sequence: "1",
        admin_id: data.adminid,
        access_key: access_key,
        authtoken: data.token,
        newdata: data.newdata,
      }),
    }).then((response) => response.json());
  }
}
