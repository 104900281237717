import React, { useEffect, useState } from "react";
import "./Add.css";
import { Main_Color } from "./../../../../Theme/Theme";
import { AiFillCloseCircle } from "react-icons/ai";
import Backend from "../../../../Backend/Backend";
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";
import RecipeScreenComp from "./RecipeScreenComp";
const backend = new Backend();
const Add = (props) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = useState(localStorage.getItem("adminid"));
  const [selected, setSelcted] = useState([]);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const [items_recipe, setItems_recipe] = useState([]);

  useEffect(() => {
    loadconent();
  }, []);

  const loadconent = () => {
    setLoading(true);

    var data = {
      token: token,
      adminid: adminid,
      product_id: props.data.id,
    };
    backend.load_recipe_items(data).then((r) => {
      if (r.error == "False") {
        setItems_recipe(r.data);
        setLoading(false);
      }
    });
  };

  const _add_recipe = () => {
    let data = {
      token: token,
      adminid: adminid,
      product_id: props.data.id,
      raw_items: selected,
    };
    setLoading(true);
    backend.add_raw_items(data).then((r2) => {
      setLoading(false);
      if (r2.error === "False") {
        props.close();
      } else {
        alert.show(r2.message);
      }
    });
  };

  const AddRowitem = (e) => {
    var arry = selected;

    arry.push(e);

    setSelcted(arry);
  };
  const removeRowItem = (e) => {
    var arry = selected;
    var index = arry.indexOf(e);

    arry.splice(index, 1);
    var newarry = [];

    arry.map((i) => {
      newarry.push(i);
    });

    setSelcted(newarry);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <div
        style={{
          height: "75%",
          width: "40%",
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          display: "flex",
          alignSelf: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 20,
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: Main_Color,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>
            Add Recipe - {props.data.product_name}
          </div>
          <div onClick={() => props.close()}>
            <AiFillCloseCircle style={{ height: "40px", width: "40px" }} />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: "#ffffff",
                height: "300px",
                width: "70%",
                borderRadius: 10,
                padding: 10,
                overflow: "scroll",
              }}
            >
              {items_recipe.map((i, t) => {
                return (
                  <RecipeScreenComp
                    data={i}
                    key={t}
                    add={(a) => AddRowitem(a)}
                    remove={(a) => removeRowItem(a)}
                  />
                );
              })}
            </div>

            {loading === true ? (
              <div
                style={{
                  alignSelf: "center",
                  marginTop: 40,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                <ReactLoading
                  type={"bars"}
                  color={"#fff"}
                  height={45}
                  width={45}
                />
              </div>
            ) : (
              <div
                onClick={_add_recipe}
                style={{
                  alignSelf: "center",
                  marginTop: 40,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                Submit
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
