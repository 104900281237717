import React from "react";
import { AiFillStar } from "react-icons/ai";
import { Main_Color } from "../../../Theme/Theme";
const VariantCard = (props) => {
  const i = props.item;
  return (
    <div key={i.id}>
      <div
        style={{
          display: "flex",
          flex: 1,
          backgroundColor: "#ffffff",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 10,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div style={{ paddingLeft: 20, display: "flex", alignItems: "center" }}>
          <div style={{ paddingRight: 20, color: "#585858" }}>
            {props.index + 1}
          </div>
          <div>
            <div style={{ color: "#000000" }}>
              {i.variant_name}
              {i.fav == 1 ? <AiFillStar style={{ color: Main_Color }} /> : null}
            </div>

            <div
              style={{
                color: "#000",
                marginTop: 5,
                fontWeight: "bold",
              }}
            >
              Price - {i.default_price}
            </div>
            <div
              style={{
                color: "#585858",
                marginTop: 5,
                fontWeight: "bold",
                fontSize: 14,
              }}
            >
              Print Name - {i.product_name}
            </div>
            {i.unicode_name == "" ? null : (
              <div
                style={{
                  color: "#585858",
                  marginTop: 5,
                  fontWeight: "bold",
                }}
              >
                Unicode Name - {i.unicode_name}
              </div>
            )}
            <div
              style={{
                color: "#585858",
                marginTop: 5,
                fontWeight: "bold",
              }}
            >
              Stock - {i.stock == null ? "0" : i.stock}
            </div>
            <div
              style={{
                color: i.status == 0 ? "red" : "green",
                marginTop: 5,
                fontWeight: "bold",
              }}
            >
              {i.status == 0 ? "Deactive" : "Active"}
            </div>
          </div>
        </div>
        <div
          style={{
            marginRight: 40,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => props.edit_modal(i)}
            style={{
              height: "40px",
              paddingLeft: 10,
              paddingRight: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: Main_Color,
              marginRight: 10,
              borderRadius: 7,
            }}
          >
            <div style={{ color: "#fff", fontWeight: "bold" }}>Edit</div>
          </div>
          <div
            onClick={() => props.stock_modal(i)}
            style={{
              height: "40px",
              paddingLeft: 20,
              paddingRight: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: Main_Color,
              marginRight: 10,
              borderRadius: 7,
            }}
          >
            <div style={{ color: "#fff", fontWeight: "bold" }}>Stock</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VariantCard;
