import React, { useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { Main_Color } from "../../../Theme/Theme";
import Backend from "../../../Backend/Backend";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
const backend = new Backend();
const RegisterCard = () => {
  const [filter, setFilter] = React.useState(false);
  const [worker, setWorker] = React.useState("");
  const [workerdata, setWorkerData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [start, setStart] = React.useState(
    moment(new Date()).format("YYYY-MM-DD 00:00:00")
  );
  const [end, setEnd] = React.useState(
    moment(new Date()).format("YYYY-MM-DD 23:59:59")
  );
  const [data, setData] = React.useState([]);

  useEffect(() => {
    loadconent();
    loadworkerlist();
  }, []);

  const loadworkerlist = () => {
    let data = {
      token: localStorage.getItem("token"),
      adminid: localStorage.getItem("adminid"),
    };
    backend.workerlist(data).then((r) => {
      let arrayObj = r.data;
      arrayObj = arrayObj.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setWorkerData(arrayObj);
      setLoading(false);
    });
  };

  const loadconent = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      adminid: localStorage.getItem("adminid"),
      datefrom: start,
      dateto: end,
      worker_id: worker,
    };

    backend
      .registerlist(data)
      .then((r) => {
        setData(r.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div>
      {filter == true ? (
        <div
          style={{
            height: "55px",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            borderRadius: 10,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>Worker </div>
            <div>
              <Dropdown
                style={{ height: "40px" }}
                value={worker}
                options={workerdata}
                onChange={(e) => {
                  setWorker(e.value);
                }}
                placeholder="Select a City"
              />
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>Start Date</div>
            <div>
              <Calendar
                style={{ height: "40px" }}
                dateFormat="dd/mm/yy"
                value={start}
                onChange={(e) => {
                  setStart(moment(e.value).format("YYYY-MM-DD 00:00:00"));
                }}
                maxDate={new Date()}
              ></Calendar>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>End Date</div>
            <div>
              <Calendar
                dateFormat="dd/mm/yy"
                style={{ height: "40px" }}
                value={end}
                onHide={() => loadconent()}
                onChange={(e) => {
                  setEnd(moment(e.value).format("YYYY-MM-DD 23:59:59"));
                }}
                maxDate={new Date()}
              ></Calendar>
            </div>
          </div>
          <div
            onClick={() => setFilter(false)}
            style={{
              backgroundColor: "#f2f2f2",
              padding: 7,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AiFillCloseCircle style={{ fontSize: 32, color: Main_Color }} />
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "40px",
            backgroundColor: "#f2f2f2",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => setFilter(true)}
            style={{
              backgroundColor: "#ffffff",
              padding: 10,
              marginRight: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              marginTop: 5,
              height: "40px",
              width: "120px",
            }}
          >
            <FiFilter style={{ fontSize: 30, color: Main_Color }} />
            <div style={{ paddingLeft: 7 }}>Filter</div>
          </div>
        </div>
      )}
      {loading == true ? (
        <div
          style={{
            flex: 1,
            backgroundColor: "#fff",
            marginTop: 10,
            height: "78vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={require("../../../Image/loading.gif")} />
        </div>
      ) : data.length > 0 ? (
        data.map((i) => {
          return (
            <div
              style={{
                display: "flex",
                flex: 1,
                backgroundColor: "#ffffff",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: 10,
                padding: 10,
                marginTop: 10,
              }}
            >
              <div
                style={{
                  paddingLeft: 20,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ paddingRight: 20, color: "#585858" }}>{i.id}</div>
                <div>
                  <div
                    style={{
                      color: "#000000",
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    {i.workername}
                  </div>
                  <div
                    style={{
                      color: i.open_close == 1 ? "green" : "red",
                      fontWeight: "bold",
                      marginTop: 2,
                    }}
                  >
                    {i.open_close == 1 ? "Active" : "Deactive"}
                  </div>
                  <div
                    style={{
                      color: "#000",
                      fontWeight: "bold",
                      marginTop: 2,
                      fontSize: 20,
                      display: "flex",
                    }}
                  >
                    Cash In Hand -
                    <div style={{ color: "green" }}>{" " + i.cashinhand}</div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  paddingLeft: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ color: "green", fontWeight: "bold" }}>
                    Open Cash - {i.opencash}
                  </div>
                  <div
                    style={{ color: "red", marginTop: 5, fontWeight: "bold" }}
                  >
                    Close Cash - {i.closecash}
                  </div>
                </div>
              </div>
              <div
                style={{
                  paddingLeft: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ color: "green", fontWeight: "bold" }}>
                    Open Time - {i.open_time}
                  </div>
                  <div
                    style={{ color: "red", marginTop: 5, fontWeight: "bold" }}
                  >
                    Close Time -{" "}
                    {i.closetime == "0000-00-00 00:00:00"
                      ? "REGISTER IS ACTIVE"
                      : i.closetime}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div
          style={{
            flex: 1,
            marginTop: 10,
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "76vh",
          }}
        >
          <img
            src={require("../../../Image/noData.gif")}
            style={{ height: "50vh" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: "bold", color: "gray" }}>
              NO DATA FOUND
            </div>
            <div
              onClick={() => loadconent()}
              style={{
                fontWeight: "bold",
                color: "gray",
                marginTop: 20,
                padding: 15,
                backgroundColor: Main_Color,
                borderRadius: 10,
                color: "#fff",
              }}
            >
              RELOAD
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegisterCard;
