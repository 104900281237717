import { Routes, Route, Link, HashRouter } from "react-router-dom";
import Login from "./Screen/Login/Login";
import Home from "./Screen/Home/Home";
import Report from "./Screen/Report/Report";
import SideBar from "./Screen/Auth/SideBar";
import Captain from "./Screen/Captain/Captain";
import Index from "./Screen/Product/index";
import Sequence from "./Screen/Sequence/Sequence";
import Categories from "./Screen/Categories/Categories";
import Tables from "./Screen/Tables/Tables";
import RawItems from "./Screen/RawItems/RawItems";
import Comments from "./Screen/Comments/Comments";
import Reasons from "./Screen/Reasons/Reasons";
import Payments from "./Screen/Payments/Payments";
import Swiggy from "./Screen/Swiggy/Swiggy";
import Profile from "./Screen/Profile/Profile";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { Offline, Online } from "react-detect-offline";
const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
};
function App() {
  if (
    localStorage.getItem("token") == null ||
    localStorage.getItem("token") == "" ||
    localStorage.getItem("token") == undefined
  ) {
    localStorage.clear();
  } else {
  }

  return (
    <div>
      {/* <Online> */}
      <div className="App">
        {localStorage.getItem("isLogged") == "1" ? (
          <div style={{ display: "flex" }}>
            <div
              style={{
                height: "100vh",
                width: 90,
                backgroundColor: "#f9f9f9",
              }}
            >
              <SideBar />
            </div>
            <Provider template={AlertTemplate} {...options}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/captain" element={<Captain />} />
                <Route path="/product" element={<Index />} />
                <Route path="/sequence" element={<Sequence />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/tables" element={<Tables />} />
                <Route path="/rawitems" element={<RawItems />} />
                <Route path="/report" element={<Report />} />
                <Route path="/comments" element={<Comments />} />
                <Route path="/reasons" element={<Reasons />} />
                <Route path="/payment" element={<Payments />} />
                <Route path="/swiggy" element={<Swiggy />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="*" element={<Home />} />
              </Routes>
            </Provider>
          </div>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        )}
      </div>
      {/* </Online> */}
      {/* <Offline>
        <div
          style={{
            flex: 1,
            backgroundColor: "#000000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={require("./Image/NoInternet.gif")}
            style={{ height: "100vh" }}
          />
        </div>
      </Offline> */}
    </div>
  );
}

export default App;
