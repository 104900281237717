import React from "react";
import { Main_Color } from "../../../Theme/Theme";
const RecipeCard = (props) => {
  const i = props.item;
  return (
    <div key={i.id}>
      <div
        style={{
          display: "flex",
          flex: 1,
          backgroundColor: "#ffffff",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 10,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div style={{ paddingLeft: 20, display: "flex", alignItems: "center" }}>
          <div style={{ paddingRight: 20, color: "#585858" }}>{i.raw_id}</div>
          <div>
            <div style={{ color: "#000000" }}>{i.raw_name}</div>
            <div
              style={{
                color: "green",
                marginTop: 5,
                fontWeight: "bold",
              }}
            >
              {i.amount}-{i.unit}
            </div>
          </div>
        </div>
        <div
          style={{
            marginRight: 40,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => props.edit_modal(i)}
            style={{
              height: "40px",
              paddingLeft: 10,
              paddingRight: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: Main_Color,
              marginRight: 10,
              borderRadius: 7,
            }}
          >
            <div style={{ color: "#fff", fontWeight: "bold" }}>
              Add Recipe Amount
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeCard;
