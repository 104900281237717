import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Main_Color } from "../../Theme/Theme";
import { BiLogOut } from "react-icons/bi";

import "./SideBar.css";
import { AiOutlineSwap } from "react-icons/ai";
function SideBar() {
  const [selected, setSelcted] = useState(1);

  return (
    <div style={{ flexDirection: "column", display: "flex" }}>
      <Link
        to={"/home"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/home" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(1)}
          style={{
            height: "60px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/home" ? 5 : 0,
            borderColor: Main_Color,
            backgroundColor:
              window.location.pathname === "/home" ? "#fff" : null,
          }}
        >
          {window.location.pathname === "/home" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Home.png")}
                style={{ height: 30, width: 30 }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Home.png")}
                style={{ height: 30, width: 30 }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Home
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link
        to={"/captain"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/captain" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(2)}
          style={{
            height: "60px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/captain" ? 5 : 0,
            borderColor: Main_Color,
            backgroundColor:
              window.location.pathname === "/captain" ? "#fff" : null,
          }}
        >
          {window.location.pathname === "/captain" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Captain.png")}
                style={{ height: 30, width: 30 }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Captain.png")}
                style={{ height: 30, width: 30 }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Captain
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link
        to={"/sequence"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/sequence" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(3)}
          style={{
            height: "60px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/sequence" ? 5 : 0,
            borderColor: Main_Color,
            backgroundColor:
              window.location.pathname === "/sequence" ? "#fff" : null,
          }}
        >
          {window.location.pathname === "/sequence" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AiOutlineSwap style={{ fontSize: 27, color: Main_Color }} />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AiOutlineSwap style={{ fontSize: 27, color: Main_Color }} />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Sequence
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link
        to={"/product"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/product" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(4)}
          style={{
            height: "60px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/product" ? 5 : 0,
            borderColor: Main_Color,
            backgroundColor:
              window.location.pathname === "/product" ? "#fff" : null,
          }}
        >
          {window.location.pathname === "/product" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Product.png")}
                style={{ height: 30, width: 30 }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Product.png")}
                style={{ height: 30, width: 30 }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Product
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link
        to={"/categories"}
        style={{
          textDecoration: "none",
          color:
            window.location.pathname === "/categories" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(5)}
          style={{
            height: "60px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/categories" ? 5 : 0,
            backgroundColor:
              window.location.pathname === "/categories" ? "#fff" : null,
          }}
        >
          {window.location.pathname === "/categories" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Categorie.png")}
                style={{ height: 30, width: 30 }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Categorie.png")}
                style={{ height: 30, width: 30 }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Categorie
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link
        to={"/tables"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/tables" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(6)}
          style={{
            height: "60px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/tables" ? 5 : 0,
            borderColor: Main_Color,
          }}
        >
          {window.location.pathname === "/tables" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Table.png")}
                style={{ height: 30, width: 30 }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Table.png")}
                style={{ height: 30, width: 30 }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Tables
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link
        to={"/rawitems"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/rawitems" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(7)}
          style={{
            height: "60px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/rawitems" ? 5 : 0,
            borderColor: Main_Color,
          }}
        >
          {window.location.pathname === "/rawitems" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/RawItem.png")}
                style={{ height: 30, width: 30 }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/RawItem.png")}
                style={{ height: 30, width: 30 }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Raw Items
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link
        to={"/report"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/report" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(8)}
          style={{
            height: "60px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/report" ? 5 : 0,
            borderColor: Main_Color,
            backgroundColor:
              window.location.pathname === "/report" ? "#fff" : null,
          }}
        >
          {window.location.pathname === "/report" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Report.png")}
                style={{ height: 30, width: 30 }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Report.png")}
                style={{ height: 30, width: 30 }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Report
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link
        to={"/comments"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/comments" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(9)}
          style={{
            height: "60px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/comments" ? 5 : 0,
            borderColor: Main_Color,
          }}
        >
          {window.location.pathname === "/comments" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Commnet.png")}
                style={{ height: 30, width: 30 }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Commnet.png")}
                style={{ height: 30, width: 30 }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Comment
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link
        to={"/reasons"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/reasons" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(10)}
          style={{
            height: "60px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/reasons" ? 5 : 0,
            borderColor: Main_Color,
          }}
        >
          {window.location.pathname === "/reasons" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Reasons.png")}
                style={{ height: 30, width: 30 }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Reasons.png")}
                style={{ height: 30, width: 30 }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Reasons
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link
        to={"/payment"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/payment" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(11)}
          style={{
            height: "60px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/payment" ? 5 : 0,
            borderColor: Main_Color,
          }}
        >
          {window.location.pathname === "/payment" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Payments.png")}
                style={{ height: 30, width: 30 }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Payments.png")}
                style={{ height: 30, width: 30 }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Payment
              </div>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

export default SideBar;
