import React, { Component } from "react";
import TopCard from "./TopCard/TopCard";
import { RiBillLine } from "react-icons/ri";
import { FaCashRegister } from "react-icons/fa";
import { IoFastFoodSharp } from "react-icons/io5";
import { FcExpired } from "react-icons/fc";
import { Main_Color } from "../../Theme/Theme";
import Backend from "../../Backend/Backend";
import { Link } from "react-router-dom";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip,
//   Legend,
//   CartesianGrid,
// } from "recharts";
// const data = [{ name: "Page A", uv: 400, pv: 2400, amt: 2400 }];
const backend = new Backend();
export default class Home extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      token: localStorage.getItem("token"),
      adminid: localStorage.getItem("adminid"),
      admin_data: [],
      admin_data2: JSON.parse(localStorage.getItem("admin_data2")),
      expiry: false,
    };
  }

  componentDidMount() {
    this.loadconent();
  }

  logoutnew() {
    localStorage.clear();
    window.location = "/Login";
  }

  loadconent() {
    this.setState({ loading: true });
    backend.homeapi(this.state).then((r) => {
      if (r.error == "False") {
        this.setState({ loading: false, admin_data: r.data });
        localStorage.setItem("admindata", JSON.stringify(r.data));
        if (r.data.days > 0) {
          this.setState({
            expiry: false,
          });
        } else {
          this.setState({
            expiry: true,
          });
        }
      } else {
        this.setState({ loading: false });
      }
    });
  }
  render() {
    return this.state.expiry == true ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          backgroundColor: "#f9fafe",
          flexDirection: "column",
        }}
      >
        <img
          src={require("../../Image/expiry.png")}
          style={{ height: "350px" }}
        />
        <div
          style={{
            fontSize: 30,
            margin: 20,
            color: "rgba(0,0,0,0.5)",
            marginBottom: 5,
          }}
        >
          Dear PosTick Pos Customer
        </div>
        <div
          style={{
            fontSize: 16,
            margin: 20,
            color: "rgba(0,0,0,0.2)",
            marginTop: 5,
          }}
        >
          Your PosTick Device Rental plan will be Expired
        </div>
        <div
          style={{
            height: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Main_Color,
            color: "#ffffff",
            borderRadius: 30,
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          Contect Support - 8758760198
        </div>

        <div
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
          style={{
            height: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Main_Color,
            color: "#ffffff",
            borderRadius: 30,
            paddingLeft: "20px",
            paddingRight: "20px",
            marginTop: "20px",
          }}
        >
          LogOut
        </div>
      </div>
    ) : this.state.loading === true ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <img src={require("../../Image/loading.gif")} />
      </div>
    ) : (
      <div style={{ background: "#f2f2f2", flex: 1 }}>
        <TopCard data={this.state.admin_data} />
        <div
          style={{
            paddingLeft: 45,
            paddingRight: 45,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              height: "70px",
              backgroundColor: "#ffffff",
              marginBottom: 20,
              borderRadius: 10,
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div>
              Today's Salses -
              {this.state.admin_data.today
                ? this.state.admin_data.today
                : " 0.00"}
            </div>
            <div>
              Yesterday's Salses -
              {this.state.admin_data.yesterday
                ? this.state.admin_data.yesterday
                : " 0.00"}
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div
            style={{
              height: "150px",
              backgroundColor: "#ffffff",
              width: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <RiBillLine
                  style={{ fontSize: 44, color: "rgba(230, 46, 79, 0.7)" }}
                />
              </div>
              <div style={{ marginTop: 7, fontSize: 22 }}>
                {this.state.admin_data.totalbills}
              </div>
              <div style={{ marginTop: 7 }}>Today's Bills</div>
            </div>
          </div>
          <div
            style={{
              height: "150px",
              backgroundColor: "#ffffff",
              width: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <FaCashRegister
                  style={{ fontSize: 44, color: "rgba(230, 46, 79, 0.7)" }}
                />
              </div>
              <div style={{ marginTop: 7, fontSize: 22 }}>
                {this.state.admin_data.totalregisters}
              </div>
              <div style={{ marginTop: 7 }}>Today's Registers</div>
            </div>
          </div>
          <div
            style={{
              height: "150px",
              backgroundColor: "#ffffff",
              width: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <IoFastFoodSharp
                  style={{ fontSize: 44, color: "rgba(230, 46, 79, 0.7)" }}
                />
              </div>
              <div style={{ marginTop: 7, fontSize: 22 }}>
                {" "}
                {this.state.admin_data.itemtotal}
              </div>
              <div style={{ marginTop: 7 }}>Today's Item</div>
            </div>
          </div>
          <div
            style={{
              height: "150px",
              backgroundColor: "#ffffff",
              width: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <FcExpired style={{ fontSize: 44, color: Main_Color }} />
              </div>
              <div style={{ marginTop: 7, fontSize: 22 }}>
                {" "}
                {this.state.admin_data.days}
              </div>
              <div style={{ marginTop: 7 }}>Service Validity</div>
            </div>
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "20px",
            marginLeft: "45px",
          }}
        >
          <div style={{ marginTop: "20px" }}>
            <Link
              to={
                localStorage.getItem("isLogged_zomato") == "1"
                  ? "/zomato_home"
                  : "/zomato_login"
              }
            >
              <img
                src="https://b.zmtcdn.com/images/logo/square_zomato_logo_new-2.svg"
                class="d-block mx-auto mt-4"
                alt=""
              />
            </Link>
          </div>
          <div style={{ marginTop: "20px" }}>
            <img
              style={{ height: "150px", width: "180px", marginLeft: "30px" }}
              src={require("../../Image/swiggylogo.png")}
              class="d-block mx-auto mt-4"
              alt=""
            />
          </div>
        </div> */}
        {/* <div>
          <BarChart width={600} height={300} data={data}>
            <XAxis dataKey="name" stroke="#8884d8" />
            <YAxis />
            <Tooltip wrapperStyle={{ width: 100, backgroundColor: "#ccc" }} />
            <Legend
              width={100}
              wrapperStyle={{
                top: 40,
                right: 20,
                backgroundColor: "#f5f5f5",
                border: "1px solid #d5d5d5",
                borderRadius: 3,
                lineHeight: "40px",
              }}
            />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Bar dataKey="uv" fill="#8884d8" barSize={30} />
          </BarChart>
        </div> */}

        <div
          style={{
            paddingLeft: 45,
            paddingRight: 45,
            display: "flex",
            color: Main_Color,
            alignItems: "center",
            marginTop: "50px",
            fontSize: 22,
          }}
        >
          The Most-Popular Dishes at Your Restaurants
        </div>

        <div
          style={{
            paddingLeft: 45,
            paddingRight: 45,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              height: "70px",
              backgroundColor: "#ffffff",
              marginBottom: 20,
              borderRadius: 10,
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div>
              {}
              {this.state.admin_data.today
                ? this.state.admin_data.max_qty_item
                : " 0.00"}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
