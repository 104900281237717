import React, { Component } from "react";
import { Main_Color } from "../../../Theme/Theme";
import { FcManager } from "react-icons/fc";
import { Link } from "react-router-dom";
export default class TopCard extends Component {
  render() {
    return (
      <div style={{ height: "100px" }}>
        <div style={{ padding: 20, paddingLeft: 40, display: "flex" }}>
          <div
            style={{
              fontSize: 22,
              color: Main_Color,
              fontWeight: "bold",
              fontFamily: "Nexa",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div style={{ fontSize: 16 }}>
              Welcome {this.props.data.username} !
            </div>
            <div style={{ marginTop: 5 }}>{this.props.data.companyname} !</div>
            <div style={{ fontSize: 16, marginTop: 5 }}>
              Master Id : {this.props.data.admin_id}
            </div>
          </div>
          <Link to="/profile" style={{ textDecoration: "none" }}>
            <div
              style={{
                marginRight: "40px",
                backgroundColor: Main_Color,
                padding: 7,
                borderRadius: 50,
              }}
            >
              <FcManager style={{ fontSize: 55 }} />
            </div>
          </Link>
        </div>
      </div>
    );
  }
}
