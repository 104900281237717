import React, { useState } from "react";
import "./Edit.css";
import { InputText } from "primereact/inputtext";
import { Main_Color } from "./../../../Theme/Theme";
import { AiFillCloseCircle } from "react-icons/ai";
import Backend from "../../../Backend/Backend";
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";
import { Checkbox } from "primereact/checkbox";
const backend = new Backend();
const Edit = (props) => {
  const [name, setName] = useState(props.data.name);
  const [mobile, setMobile] = useState(props.data.mobile);
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = useState(localStorage.getItem("adminid"));
  const [alert_type, setAlert_type] = useState(localStorage.getItem("adminid"));
  const [loading, setLoading] = useState(false);
  const [accountState, setAccountState] = useState(
    props.data.status === "1" ? true : false
  );
  const [kitchenState, setKitchenState] = useState(
    props.data.kitchen_user === "1" ? true : false
  );
  const [printState, setPrintState] = useState(
    props.data.print_permission === "1" ? true : false
  );
  const [viewBillState, setViewBillState] = useState(
    props.data.report_permission === "1" ? true : false
  );
  const [editBillState, setEditBillState] = useState(
    props.data.edit_permission === "1" ? true : false
  );
  const [deactiveTableState, setDeactiveTableState] = useState(
    props.data.deactivate_permission === "1" ? true : false
  );
  const [reprintBillState, setReprintBillState] = useState(
    props.data.reprint_permission === "1" ? true : false
  );
  const [negativeOrderState, setNegativeOrderState] = useState(
    props.data.negative_permission === "1" ? true : false
  );

  const alert = useAlert();

  const _edit_worker = () => {
    if (name === "") {
      setAlert_type(1);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else if (mobile.length < 10) {
      setAlert_type(2);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else if (password.length < 3) {
      setAlert_type(3);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else {
      let data = {
        name: name,
        mobile: mobile,
        password: password,
        token: token,
        adminid: adminid,
        worker_id: props.data.id,
        status: accountState,
        kitchen: kitchenState,
        reset_bill: props.data.reset_bill,
        report_permission: viewBillState,
        edit_permission: editBillState,
        deactivate_permission: deactiveTableState,
        reprint_permission: reprintBillState,
        negative_permission: negativeOrderState,
        print_permission: printState,
        kiosk: props.data.kiosk,
      };
      setLoading(true);
      backend.editworker(data).then((r2) => {
        setLoading(false);
        if (r2.error === "False") {
          props.close();
        } else {
          alert.show(r2.message);
        }
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <div
        style={{
          height: "70%",
          width: "65%",
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          display: "flex",
          alignSelf: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 20,
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: Main_Color,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>Edit Captain</div>
          <div onClick={() => props.close()}>
            <AiFillCloseCircle style={{ height: "40px", width: "40px" }} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span
                className="p-float-label"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <InputText
                  placeholder="Worker Name "
                  style={{
                    width: "500px",
                    marginTop: 10,
                    borderWidth: alert_type === 1 ? 2 : 1,
                    borderColor: alert_type === 1 ? Main_Color : "",
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {alert_type === 1 ? (
                  <div
                    style={{ marginTop: 5, paddingLeft: 10, color: Main_Color }}
                  >
                    {"Invalid Worker Name "}
                  </div>
                ) : null}
              </span>
            </div>
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span
                className="p-float-label"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <InputText
                  placeholder="Mobile Number"
                  maxLength={10}
                  value={mobile}
                  min="0"
                  max="10"
                  pattern="[0-9]{2}"
                  style={{
                    width: "500px",
                    marginTop: 10,
                    borderWidth: alert_type === 2 ? 2 : 1,
                    borderColor: alert_type === 2 ? Main_Color : "",
                  }}
                  onChange={(e) => setMobile(e.target.value)}
                />
                {alert_type === 2 ? (
                  <div
                    style={{ marginTop: 5, paddingLeft: 10, color: Main_Color }}
                  >
                    {"Invalid Worker Mobile Number "}
                  </div>
                ) : null}
              </span>
            </div>
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span
                className="p-float-label"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <InputText
                  placeholder="Password"
                  type="password"
                  style={{
                    width: "500px",
                    marginTop: 10,
                    borderWidth: alert_type === 3 ? 2 : 1,
                    borderColor: alert_type === 3 ? Main_Color : "",
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {alert_type === 2 ? (
                  <div
                    style={{ marginTop: 5, paddingLeft: 10, color: Main_Color }}
                  >
                    {"Invalid Worker Password "}
                  </div>
                ) : null}
              </span>
            </div>
            <div
              style={{
                padding: 10,
                justifyContent: "flex-start",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
                alignItems: "center",
                paddingLeft: 40,
              }}
            >
              <Checkbox
                onChange={(e) => setAccountState(e.checked)}
                checked={accountState}
              ></Checkbox>
              <div
                style={{
                  color: accountState === true ? "green" : "red",
                  paddingLeft: 15,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                {accountState == true ? "Active" : "Deactive"}
              </div>
            </div>
            {loading === true ? (
              <div
                style={{
                  alignSelf: "center",
                  marginTop: 40,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                <ReactLoading
                  type={"bars"}
                  color={"#fff"}
                  height={45}
                  width={45}
                />
              </div>
            ) : (
              <div
                onClick={() => _edit_worker()}
                style={{
                  alignSelf: "center",
                  marginTop: 40,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                Submit
              </div>
            )}
          </div>

          <div style={{ flex: 1 }}>
            <div>
              <div
                style={{
                  padding: 10,
                  justifyContent: "center",
                  display: "flex",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  color: "#ffffff",
                  alignItems: "center",
                  paddingLeft: 50,
                }}
              >
                <div
                  style={{
                    color: kitchenState === true ? "green" : "red",
                    fontSize: 18,
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  Kitchen User
                </div>
                <div style={{ paddingLeft: 60 }}>
                  <Checkbox
                    onChange={(e) => setKitchenState(e.checked)}
                    checked={kitchenState}
                  ></Checkbox>
                </div>
              </div>
              <div
                style={{
                  padding: 10,
                  justifyContent: "center",
                  display: "flex",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  color: "#ffffff",
                  alignItems: "center",
                  paddingLeft: 50,
                }}
              >
                <div
                  style={{
                    color: printState === true ? "green" : "red",
                    fontSize: 18,
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  Print Permission
                </div>
                <div style={{ paddingLeft: 60 }}>
                  <Checkbox
                    onChange={(e) => setPrintState(e.checked)}
                    checked={printState}
                  ></Checkbox>
                </div>
              </div>

              <div
                style={{
                  padding: 10,
                  justifyContent: "center",
                  display: "flex",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  color: "#ffffff",
                  alignItems: "center",
                  paddingLeft: 50,
                }}
              >
                <div
                  style={{
                    color: viewBillState === true ? "green" : "red",
                    fontSize: 18,
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  View Report Permission
                </div>
                <div style={{ paddingLeft: 60 }}>
                  <Checkbox
                    onChange={(e) => setViewBillState(e.checked)}
                    checked={viewBillState}
                  ></Checkbox>
                </div>
              </div>

              <div
                style={{
                  padding: 10,
                  justifyContent: "center",
                  display: "flex",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  color: "#ffffff",
                  alignItems: "center",
                  paddingLeft: 50,
                }}
              >
                <div
                  style={{
                    color: editBillState === true ? "green" : "red",
                    fontSize: 18,
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  Bill Edit Permission
                </div>
                <div style={{ paddingLeft: 60 }}>
                  <Checkbox
                    onChange={(e) => setEditBillState(e.checked)}
                    checked={editBillState}
                  ></Checkbox>
                </div>
              </div>

              <div
                style={{
                  padding: 10,
                  justifyContent: "center",
                  display: "flex",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  color: "#ffffff",
                  alignItems: "center",
                  paddingLeft: 50,
                }}
              >
                <div
                  style={{
                    color: deactiveTableState === true ? "green" : "red",
                    fontSize: 18,
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  DeactiveTable Permission
                </div>
                <div style={{ paddingLeft: 60 }}>
                  <Checkbox
                    onChange={(e) => setDeactiveTableState(e.checked)}
                    checked={deactiveTableState}
                  ></Checkbox>
                </div>
              </div>

              <div
                style={{
                  padding: 10,
                  justifyContent: "center",
                  display: "flex",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  color: "#ffffff",
                  alignItems: "center",
                  paddingLeft: 50,
                }}
              >
                <div
                  style={{
                    color: reprintBillState === true ? "green" : "red",
                    fontSize: 18,
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  Reprint Bill Permission
                </div>
                <div style={{ paddingLeft: 60 }}>
                  <Checkbox
                    onChange={(e) => setReprintBillState(e.checked)}
                    checked={reprintBillState}
                  ></Checkbox>
                </div>
              </div>

              <div
                style={{
                  padding: 10,
                  justifyContent: "center",
                  display: "flex",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  color: "#ffffff",
                  alignItems: "center",
                  paddingLeft: 50,
                }}
              >
                <div
                  style={{
                    color: negativeOrderState === true ? "green" : "red",
                    fontSize: 18,
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  Negative Order Permission
                </div>
                <div style={{ paddingLeft: 60 }}>
                  <Checkbox
                    onChange={(e) => setNegativeOrderState(e.checked)}
                    checked={negativeOrderState}
                  ></Checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
