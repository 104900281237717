import React, { useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { Main_Color } from "../../../Theme/Theme";
import Backend from "../../../Backend/Backend";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import SalesCard from "./SalesCard";
const backend = new Backend();
const Sales = () => {
  const [filter, setFilter] = React.useState(false);
  const [worker, setWorker] = React.useState("");
  const [payment_id, setPayment_id] = React.useState("");
  const [workerdata, setWorkerData] = React.useState([]);
  const [payment, setPaymentData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [start, setStart] = React.useState(
    moment(new Date()).format("YYYY-MM-DD 00:00:00")
  );
  const [end, setEnd] = React.useState(
    moment(new Date()).format("YYYY-MM-DD 23:59:59")
  );
  const [data, setData] = React.useState([]);
  const [data_filter, setData_filter] = React.useState([]);

  useEffect(() => {
    loadconent();
    loadworkerlist();
  }, []);

  const loadworkerlist = () => {
    let data = {
      token: localStorage.getItem("token"),
      adminid: localStorage.getItem("adminid"),
    };
    backend.workerlist(data).then((r) => {
      let arrayObj = r.data;
      arrayObj = arrayObj.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setWorkerData(arrayObj);
    });
  };

  const loadconent = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      adminid: localStorage.getItem("adminid"),
      datefrom: start,
      dateto: end,
      worker_id: worker,
      payment_id: payment_id,
    };
    backend
      .salesreport(data)
      .then((r) => {
        setData(r.data);
        setData_filter(r.data);
        setPaymentData(r.payment_method);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const filter_payment = (i) => {
    if (i == "All") {
      setData(data_filter);
    } else {
      var data = data_filter.filter((x) => x.payment == i);

      setData(data);
    }
  };
  return (
    <div>
      {filter == true ? (
        <div
          style={{
            height: "55px",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            borderRadius: 10,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>Worker </div>
            <div>
              <Dropdown
                style={{ height: "40px" }}
                value={worker}
                options={workerdata}
                onChange={(e) => {
                  setWorker(e.value);
                }}
                placeholder="Select a City"
              />
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>Start Date</div>
            <div>
              <Calendar
                style={{ height: "40px" }}
                dateFormat="dd/mm/yy"
                value={start}
                onChange={(e) => {
                  setStart(moment(e.value).format("YYYY-MM-DD 00:00:00"));
                }}
                maxDate={new Date()}
              ></Calendar>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>End Date</div>
            <div>
              <Calendar
                dateFormat="dd/mm/yy"
                style={{ height: "40px" }}
                value={end}
                onChange={(e) => {
                  setEnd(moment(e.value).format("YYYY-MM-DD 23:59:59"));
                }}
                maxDate={new Date()}
                onHide={() => loadconent()}
              ></Calendar>
            </div>
          </div>
          <div
            onClick={() => setFilter(false)}
            style={{
              backgroundColor: "#f2f2f2",
              padding: 7,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AiFillCloseCircle style={{ fontSize: 32, color: Main_Color }} />
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "40px",
            backgroundColor: "#f2f2f2",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => setFilter(true)}
            style={{
              backgroundColor: "#ffffff",
              padding: 10,
              marginRight: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              marginTop: 5,
              height: "40px",
              width: "120px",
            }}
          >
            <FiFilter style={{ fontSize: 30, color: Main_Color }} />
            <div style={{ paddingLeft: 7 }}>Filter</div>
          </div>
        </div>
      )}
      {loading == true ? (
        <div
          style={{
            flex: 1,
            backgroundColor: "#fff",
            marginTop: 10,
            height: "78vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={require("../../../Image/loading.gif")} />
        </div>
      ) : data.length > 0 ? (
        <div style={{ display: "flex", flex: 1 }}>
          <div
            style={{
              flex: 1,
              backgroundColor: "#ffffff",
              margin: 10,
              height: "73vh",
              borderRadius: 10,
              padding: 10,
            }}
          >
            <div
              onClick={() => filter_payment("All")}
              style={{
                height: "45px",
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                marginBottom: "10px",
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  paddingLeft: "10px",
                  color: Main_Color,
                  fontWeight: "bold",
                }}
              >
                All
              </div>
              <div
                style={{
                  display: "flex",
                  paddingRight: "10px",
                  color: "#585858",
                  fontSize: 22,
                }}
              ></div>
            </div>
            {payment.map((i) => {
              return (
                <div
                  key={i.id}
                  onClick={() => filter_payment(i.payment_id)}
                  style={{
                    height: "45px",
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    marginBottom: "10px",
                    display: "flex",
                    justifyItems: "center",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      paddingLeft: "10px",
                      color: Main_Color,
                      fontWeight: "bold",
                    }}
                  >
                    {i.payment_name}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      paddingRight: "10px",
                      color: "#585858",
                      fontSize: 22,
                    }}
                  >
                    {i.total}
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ flex: 3, overflow: "scroll", height: "75vh" }}>
            {data.map((i) => {
              return <SalesCard data={i} />;
            })}
          </div>
        </div>
      ) : (
        <div
          style={{
            flex: 1,
            marginTop: 10,
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "76vh",
          }}
        >
          <img
            src={require("../../../Image/noData.gif")}
            style={{ height: "50vh" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: "bold", color: "gray" }}>
              NO DATA FOUND
            </div>
            <div
              onClick={() => loadconent()}
              style={{
                fontWeight: "bold",
                color: "gray",
                marginTop: 20,
                padding: 15,
                backgroundColor: Main_Color,
                borderRadius: 10,
                color: "#fff",
              }}
            >
              RELOAD
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sales;
