import React, { useState } from "react";
import "./Add.css";
import { InputText } from "primereact/inputtext";
import { Main_Color } from "./../../../Theme/Theme";
import { AiFillCloseCircle } from "react-icons/ai";
import Backend from "../../../Backend/Backend";
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";
const backend = new Backend();
const Add = (props) => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = useState(localStorage.getItem("adminid"));
  const [alert_type, setAlert_type] = useState(localStorage.getItem("adminid"));
  const [loading, setLoading] = useState(false);

  const alert = useAlert();

  const _add_comment = () => {
    if (name === "") {
      setAlert_type(1);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else {
      let data = {
        comment_name: name,
        token: token,
        adminid: adminid,
      };
      setLoading(true);
      backend.add_comment(data).then((r2) => {
        setLoading(false);
        if (r2.error === "False") {
          props.close();
        } else {
          alert.show(r2.message);
        }
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <div
        style={{
          height: "40%",
          width: "40%",
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          display: "flex",
          alignSelf: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 20,
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: Main_Color,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>Comment</div>
          <div onClick={() => props.close()}>
            <AiFillCloseCircle style={{ height: "40px", width: "40px" }} />
          </div>
        </div>
        <div
          style={{
            padding: 10,
            justifyContent: "center",
            display: "flex",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
          }}
        >
          <span
            className="p-float-label"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <InputText
              placeholder="Comment Name "
              style={{
                width: "500px",
                marginTop: 10,
                borderWidth: alert_type === 1 ? 2 : 1,
                borderColor: alert_type === 1 ? Main_Color : "",
              }}
              onChange={(e) => setName(e.target.value)}
            />
            {alert_type === 1 ? (
              <div style={{ marginTop: 5, paddingLeft: 10, color: Main_Color }}>
                {"Invalid Comment Name "}
              </div>
            ) : null}
          </span>
        </div>
        {loading === true ? (
          <div
            style={{
              alignSelf: "center",
              marginTop: 40,
              height: "50px",
              width: "200px",
              backgroundColor: Main_Color,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#ffffff",
              fontSize: 18,
            }}
          >
            <ReactLoading type={"bars"} color={"#fff"} height={45} width={45} />
          </div>
        ) : (
          <div
            onClick={() => _add_comment()}
            style={{
              alignSelf: "center",
              marginTop: 40,
              height: "50px",
              width: "200px",
              backgroundColor: Main_Color,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#ffffff",
              fontSize: 18,
            }}
          >
            Submit
          </div>
        )}
      </div>
    </div>
  );
};

export default Add;
