import React, { Component } from "react";
import { FcManager } from "react-icons/fc";
import { Main_Color } from "../../Theme/Theme";
import Register from "./Register/Register";
import Sales from "./Sales/Sales";
import ItemWise from "./ItemWise/ItemWise";
import Category from "./Category/Category";
import Table from "./Table/Table";
import Zomato from "./Online/Zomato";
import Swiggy from "./Online/Swiggy";
import Total from "./Online/Total";

export default class Report extends Component {
  constructor() {
    super();
    this.state = {
      selected: 1,
      navChnage: 0,
    };
  }

  render() {
    return (
      <div style={{ flex: 1, backgroundColor: "#f2f2f2" }}>
        <div
          style={{
            display: "flex",
            height: "100px",
            backgroundColor: "#ffffff",
            flex: 1,
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: 20,
              color: Main_Color,
              fontSize: 18,
              paddingLeft: 40,
              flex: 1,
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <FcManager style={{ fontSize: 55 }} />
            <div style={{ paddingLeft: 20, fontSize: 22, flex: 1 }}>Report</div>
            <div style={{ flex: 2, display: "flex", alignItems: "center" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <div
                  onClick={() => this.setState({ selected: 6, navChnage: 1 })}
                  style={{
                    height: "40px",
                    width: "100px",
                    backgroundColor:
                      this.state.selected == 6 ? Main_Color : "#f2f2f2",
                    borderRadius: 10,
                    color: this.state.selected == 6 ? "#ffffff" : "#585858",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Online
                </div> */}
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => this.setState({ selected: 1 })}
                  style={{
                    height: "40px",
                    width: "100px",
                    backgroundColor:
                      this.state.selected == 1 ? Main_Color : "#f2f2f2",
                    borderRadius: 10,
                    color: this.state.selected == 1 ? "#ffffff" : "#585858",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Register
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => this.setState({ selected: 2 })}
                  style={{
                    height: "40px",
                    width: "100px",
                    backgroundColor:
                      this.state.selected == 2 ? Main_Color : "#f2f2f2",
                    borderRadius: 10,
                    color: this.state.selected == 2 ? "#ffffff" : "#585858",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Sales
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => this.setState({ selected: 3 })}
                  style={{
                    height: "40px",
                    width: "100px",
                    backgroundColor:
                      this.state.selected == 3 ? Main_Color : "#f2f2f2",
                    borderRadius: 10,
                    color: this.state.selected == 3 ? "#ffffff" : "#585858",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Item Wise
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => this.setState({ selected: 4 })}
                  style={{
                    height: "40px",
                    width: "100px",
                    backgroundColor:
                      this.state.selected == 4 ? Main_Color : "#f2f2f2",
                    borderRadius: 10,
                    color: this.state.selected == 4 ? "#ffffff" : "#585858",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Category
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => this.setState({ selected: 5 })}
                  style={{
                    height: "40px",
                    width: "100px",
                    backgroundColor:
                      this.state.selected == 5 ? Main_Color : "#f2f2f2",
                    borderRadius: 10,
                    color: this.state.selected == 5 ? "#ffffff" : "#585858",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Table
                </div>
              </div>
            </div>
          </div>
          {/* {this.state.navChnage == 0 ? (
            <div
              style={{
                display: "flex",
                padding: 20,
                color: Main_Color,
                fontSize: 18,
                paddingLeft: 40,
                flex: 1,
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <FcManager style={{ fontSize: 55 }} />
              <div style={{ paddingLeft: 20, fontSize: 22, flex: 1 }}>
                Report
              </div>
              <div style={{ flex: 2, display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => this.setState({ selected: 6, navChnage: 1 })}
                    style={{
                      height: "40px",
                      width: "100px",
                      backgroundColor:
                        this.state.selected == 6 ? Main_Color : "#f2f2f2",
                      borderRadius: 10,
                      color: this.state.selected == 6 ? "#ffffff" : "#585858",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Online
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => this.setState({ selected: 1 })}
                    style={{
                      height: "40px",
                      width: "100px",
                      backgroundColor:
                        this.state.selected == 1 ? Main_Color : "#f2f2f2",
                      borderRadius: 10,
                      color: this.state.selected == 1 ? "#ffffff" : "#585858",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Register
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => this.setState({ selected: 2 })}
                    style={{
                      height: "40px",
                      width: "100px",
                      backgroundColor:
                        this.state.selected == 2 ? Main_Color : "#f2f2f2",
                      borderRadius: 10,
                      color: this.state.selected == 2 ? "#ffffff" : "#585858",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Sales
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => this.setState({ selected: 3 })}
                    style={{
                      height: "40px",
                      width: "100px",
                      backgroundColor:
                        this.state.selected == 3 ? Main_Color : "#f2f2f2",
                      borderRadius: 10,
                      color: this.state.selected == 3 ? "#ffffff" : "#585858",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Item Wise
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => this.setState({ selected: 4 })}
                    style={{
                      height: "40px",
                      width: "100px",
                      backgroundColor:
                        this.state.selected == 4 ? Main_Color : "#f2f2f2",
                      borderRadius: 10,
                      color: this.state.selected == 4 ? "#ffffff" : "#585858",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Category
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => this.setState({ selected: 5 })}
                    style={{
                      height: "40px",
                      width: "100px",
                      backgroundColor:
                        this.state.selected == 5 ? Main_Color : "#f2f2f2",
                      borderRadius: 10,
                      color: this.state.selected == 5 ? "#ffffff" : "#585858",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Table
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                padding: 20,
                color: Main_Color,
                fontSize: 18,
                paddingLeft: 40,
                flex: 1,
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <FcManager style={{ fontSize: 55 }} />
              <div style={{ paddingLeft: 20, fontSize: 22, flex: 1 }}>
                Online Report
              </div>
              <div style={{ flex: 2, display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => this.setState({ selected: 6 })}
                    style={{
                      height: "40px",
                      width: "100px",
                      backgroundColor:
                        this.state.selected == 6 ? Main_Color : "#f2f2f2",
                      borderRadius: 10,
                      color: this.state.selected == 6 ? "#ffffff" : "#585858",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Zomato
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => this.setState({ selected: 7 })}
                    style={{
                      height: "40px",
                      width: "100px",
                      backgroundColor:
                        this.state.selected == 7 ? Main_Color : "#f2f2f2",
                      borderRadius: 10,
                      color: this.state.selected == 7 ? "#ffffff" : "#585858",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Swiggy
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => this.setState({ selected: 8 })}
                    style={{
                      height: "40px",
                      width: "100px",
                      backgroundColor:
                        this.state.selected == 8 ? Main_Color : "#f2f2f2",
                      borderRadius: 10,
                      color: this.state.selected == 8 ? "#ffffff" : "#585858",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Total
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => this.setState({ selected: 1, navChnage: 0 })}
                    style={{
                      height: "40px",
                      width: "100px",
                      backgroundColor: Main_Color,
                      borderRadius: 10,
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Close
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
        <div style={{ padding: 10 }}>
          <div
            style={{
              flex: 1,
              height: "83vh",
              borderRadius: 10,
              overflowY: "scroll",
            }}
          >
            {/* {this.state.navChnage == 0 ? (
              <>
                {this.state.selected == 1 ? <Register /> : null}
                {this.state.selected == 2 ? <Sales /> : null}
                {this.state.selected == 3 ? <ItemWise /> : null}
                {this.state.selected == 4 ? <Category /> : null}
                {this.state.selected == 5 ? <Table /> : null}
              </>
            ) : (
              <>
                {this.state.selected == 6 ? <Zomato /> : null}
                {this.state.selected == 7 ? <Swiggy /> : null}
                {this.state.selected == 8 ? <Total /> : null}
              </>
            )} */}
            {this.state.selected == 1 ? <Register /> : null}
            {this.state.selected == 2 ? <Sales /> : null}
            {this.state.selected == 3 ? <ItemWise /> : null}
            {this.state.selected == 4 ? <Category /> : null}
            {this.state.selected == 5 ? <Table /> : null}
          </div>
        </div>
      </div>
    );
  }
}
