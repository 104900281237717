import React, { useState } from "react";
import { AiFillDelete, AiFillStar } from "react-icons/ai";
import { Main_Color } from "../../Theme/Theme";
import { confirmAlert } from "react-confirm-alert"; // Import
import ReactLoading from "react-loading";
import "react-confirm-alert/src/react-confirm-alert.css";
import Backend from "../../Backend/Backend";
import { useAlert } from "react-alert";
const backend = new Backend();
const ProductCard = (props) => {
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const i = props.item;

  const delete_product = () => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoading(true);
            var data = {
              token: localStorage.getItem("token"),
              adminid: localStorage.getItem("adminid"),
            };

            backend.deleteproduct(i.id, data).then((r) => {
              setLoading(false);
              if (r.error === "False") {
                alert.success(r.message);
                props.loadNew();
              } else {
                alert.error(r.message);
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  };

  return (
    <div key={i.id}>
      <div
        style={{
          display: "flex",
          flex: 1,
          backgroundColor: "#ffffff",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 10,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div style={{ paddingLeft: 20, display: "flex", alignItems: "center" }}>
          <div style={{ paddingRight: 20, color: "#585858" }}>{i.menu_sr}</div>
          <div>
            <div style={{ color: "#000000" }}>
              {i.product_name}
              {i.fav == 1 ? <AiFillStar style={{ color: Main_Color }} /> : null}
            </div>

            <div
              style={{
                color: "#000",
                marginTop: 5,
                fontWeight: "bold",
              }}
            >
              Price - {i.default_price}
            </div>
            <div
              style={{
                color: "#585858",
                marginTop: 5,
                fontWeight: "bold",
              }}
            >
              Category Name - {i.catagory_name}
            </div>
            {i.unicode_name == "" ? null : (
              <div
                style={{
                  color: "#585858",
                  marginTop: 5,
                  fontWeight: "bold",
                }}
              >
                Unicode Name - {i.unicode_name}
              </div>
            )}
            <div
              style={{
                color: "#000000",
                marginTop: 5,
                fontWeight: "bold",
              }}
            >
              Stock - {i.stock == null ? "0" : i.stock}
            </div>
            <div
              style={{
                color: "#000000",
                marginTop: 5,
                fontWeight: "bold",
              }}
            >
              {i.alias == "" ? null : "Code -"} {i.alias == "" ? null : i.alias}
            </div>
            <div
              style={{
                color: i.status == 0 ? "red" : "green",
                marginTop: 5,
                fontWeight: "bold",
              }}
            >
              {i.status == 0 ? "Deactive" : "Active"}
            </div>
          </div>
        </div>
        <div
          style={{
            marginRight: 40,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => props.edit_modal(i)}
            style={{
              height: "40px",
              paddingLeft: 10,
              paddingRight: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: Main_Color,
              marginRight: 10,
              borderRadius: 7,
            }}
          >
            <div style={{ color: "#fff", fontWeight: "bold" }}>Edit</div>
          </div>
          {i.has_variant == 1 ? (
            <div
              onClick={() => {
                props.open_variant();
                props.passdata(i);
              }}
              style={{
                height: "40px",
                paddingLeft: 10,
                paddingRight: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Main_Color,
                borderRadius: 7,
              }}
            >
              <div style={{ color: "#fff", fontWeight: "bold" }}>Variant</div>
            </div>
          ) : (
            <div
              onClick={() => {
                props.passdata(i);
                props.open_recipe(i);
              }}
              style={{
                height: "40px",
                paddingLeft: 10,
                paddingRight: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Main_Color,
                borderRadius: 7,
              }}
            >
              <div style={{ color: "#fff", fontWeight: "bold" }}>Recipe</div>
            </div>
          )}
          {i.has_variant == 0 ? (
            <div
              onClick={() => props.add_stock_modal(i)}
              style={{
                height: "40px",
                paddingLeft: 10,
                paddingRight: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Main_Color,
                marginLeft: 10,
                borderRadius: 7,
              }}
            >
              <div style={{ color: "#fff", fontWeight: "bold" }}>Stock</div>
            </div>
          ) : null}

          {loading === true ? (
            <div
              style={{
                height: "40px",
                width: "55px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReactLoading
                type={"spin"}
                color={Main_Color}
                height={30}
                width={30}
              />
            </div>
          ) : (
            <div
              onClick={delete_product}
              style={{
                height: "40px",
                width: "55px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AiFillDelete style={{ fontSize: 35, color: "red" }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
