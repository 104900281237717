import React, { useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { Main_Color } from "../../../Theme/Theme";
import Backend from "../../../Backend/Backend";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
const backend = new Backend();
const ItemWise = () => {
  const [show, setShow] = React.useState(false);
  const [filter, setFilter] = React.useState(false);
  const [worker, setWorker] = React.useState("");
  const [workerdata, setWorkerData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [start, setStart] = React.useState(
    moment(new Date()).format("YYYY-MM-DD 00:00:00")
  );
  const [end, setEnd] = React.useState(
    moment(new Date()).format("YYYY-MM-DD 23:59:59")
  );
  const [data, setData] = React.useState([]);
  const [data2, setData2] = React.useState("");

  useEffect(() => {
    loadconent();
    loadworkerlist();
  }, []);

  const loadworkerlist = () => {
    let data = {
      token: localStorage.getItem("token"),
      adminid: localStorage.getItem("adminid"),
    };
    backend.workerlist(data).then((r) => {
      let arrayObj = r.data;
      arrayObj = arrayObj.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setWorkerData(arrayObj);
      setLoading(false);
    });
  };

  const loadconent = () => {
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      adminid: localStorage.getItem("adminid"),
      datefrom: start,
      dateto: end,
      worker_id: "",
    };
    backend
      .itemsreport(data)
      .then((r) => {
        setData(r.data);
        setData2(r.data2);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div>
      {filter == true ? (
        <div
          style={{
            height: "55px",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            borderRadius: 10,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>Worker </div>
            <div>
              <Dropdown
                style={{ height: "40px" }}
                value={worker}
                options={workerdata}
                onChange={(e) => setWorker(e.value)}
                placeholder="Select a City"
              />
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>Start Date</div>
            <div>
              <Calendar
                style={{ height: "40px" }}
                dateFormat="dd/mm/yy"
                value={start}
                onChange={(e) =>
                  setStart(moment(e.value).format("YYYY-MM-DD 00:00:00"))
                }
                maxDate={new Date()}
              ></Calendar>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>End Date</div>
            <div>
              <Calendar
                dateFormat="dd/mm/yy"
                style={{ height: "40px" }}
                value={end}
                onHide={() => loadconent()}
                onChange={(e) => {
                  setEnd(moment(e.value).format("YYYY-MM-DD 23:59:59"));
                }}
                maxDate={new Date()}
              ></Calendar>
            </div>
          </div>
          <div
            onClick={() => setFilter(false)}
            style={{
              backgroundColor: "#f2f2f2",
              padding: 7,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AiFillCloseCircle style={{ fontSize: 32, color: Main_Color }} />
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "40px",
            backgroundColor: "#f2f2f2",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => setFilter(true)}
            style={{
              backgroundColor: "#ffffff",
              padding: 10,
              marginRight: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              marginTop: 5,
              height: "40px",
              width: "120px",
            }}
          >
            <FiFilter style={{ fontSize: 30, color: Main_Color }} />
            <div style={{ paddingLeft: 7 }}>Filter</div>
          </div>
        </div>
      )}
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            backgroundColor: "#ffffff",
            padding: 10,
            marginTop: 10,
            marginRight: 10,
            height: "75vh",
            borderRadius: 10,
            overflowY: "scroll",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: 10,
              backgroundColor: Main_Color,
              borderRadius: 10,
            }}
          >
            <div style={{ flex: 1, color: "#fff", fontWeight: "bold" }}>
              Item Name
            </div>
            <div style={{ flex: 1, color: "#fff", fontWeight: "bold" }}>
              Rate
            </div>
            <div style={{ flex: 1, color: "#fff", fontWeight: "bold" }}>
              Qty
            </div>
            <div style={{ flex: 1, color: "#fff", fontWeight: "bold" }}>
              Amount
            </div>
          </div>
          <div style={{ flex: 1, display: "flex", padding: 10 }}>
            {loading == true ? (
              <div
                style={{
                  flex: 1,
                  backgroundColor: "#fff",
                  marginTop: 10,
                  height: "60vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={require("../../../Image/loading.gif")} />
              </div>
            ) : data.length > 0 ? (
              <div style={{ flex: 1 }}>
                {data.map((i, t) => {
                  return (
                    <div
                      style={{
                        height: "50px",
                        display: "flex",
                        flex: 1,
                        backgroundColor: "#f2f2f2",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingLeft: 10,
                        borderRadius: 2,
                        borderStyle: "solid",
                        borderWidth: 0,
                        borderBottomWidth: 1,
                        borderBottomColor: Main_Color,
                        borderTopLeftRadius: t == 0 ? 10 : 0,
                        borderTopRightRadius: t == 0 ? 10 : 0,
                      }}
                    >
                      <div style={{ flex: 1 }}>{i.product_name}</div>
                      <div style={{ flex: 1 }}>
                        {parseFloat(i.rate).toFixed(2)}
                      </div>
                      <div style={{ flex: 1 }}>
                        {parseFloat(i.totalqty).toFixed(2)}
                      </div>
                      <div style={{ flex: 1 }}>
                        {parseFloat(i.totalamt).toFixed(2)}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  flex: 1,
                  marginTop: 10,
                  backgroundColor: "#ffffff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "76vh",
                }}
              >
                <img
                  src={require("../../../Image/noData.gif")}
                  style={{ height: "50vh" }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold", color: "gray" }}>
                    NO DATA FOUND
                  </div>
                  <div
                    onClick={() => loadconent()}
                    style={{
                      fontWeight: "bold",
                      color: "gray",
                      marginTop: 20,
                      padding: 15,
                      backgroundColor: Main_Color,
                      borderRadius: 10,
                      color: "#fff",
                    }}
                  >
                    RELOAD
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 0.5,
            backgroundColor: "#ffffff",
            padding: 10,
            marginTop: 10,
            height: "75vh",
            borderRadius: 10,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22, fontWeight: "bold", marginTop: 10 }}>
            Discount : {data2.discount}
          </div>
          <div style={{ fontSize: 22, fontWeight: "bold", marginTop: 10 }}>
            CGST : {data2.cgst}
          </div>
          <div style={{ fontSize: 22, fontWeight: "bold", marginTop: 10 }}>
            SGST : {data2.sgst}
          </div>
          <div style={{ fontSize: 22, fontWeight: "bold", marginTop: 10 }}>
            Round Off : {data2.roundoff}
          </div>
          <div
            style={{
              fontSize: 22,
              fontWeight: "bold",
              marginTop: 10,
              color: "green",
            }}
          >
            Grand Total : {data2.totaltotal}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemWise;
