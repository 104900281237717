import React, { useEffect, useState } from "react";
import "./Edit.css";
import { InputText } from "primereact/inputtext";
import { Main_Color } from "./../../../../Theme/Theme";
import { AiFillCloseCircle } from "react-icons/ai";
import Backend from "../../../../Backend/Backend";
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";
const backend = new Backend();
const Edit = (props) => {
  const [name, setName] = useState(props.data.product_name);
  const [catagory_id, setCatagory_id] = useState(props.data.cat_id);
  const [category_list, setCategory_list] = useState([]);
  const [price, setPrice] = useState(props.data.default_price);
  const [unicode_name, setUnicode_name] = useState(props.data.unicode_name);
  const [alias, setAlias] = useState(props.alias);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = useState(localStorage.getItem("adminid"));
  const [alert_type, setAlert_type] = useState(localStorage.getItem("adminid"));
  const [loading, setLoading] = useState(false);
  const [fav, setFav] = useState(props.data.fav == "1" ? true : false);
  const [hasvarinty, setHasvarinty] = useState(
    props.data.has_variant == "1" ? true : false
  );
  const [accountState, setAccountState] = useState(
    props.data.status == "1" ? true : false
  );

  const [count, setCount] = useState(0);
  const alert = useAlert();

  useEffect(() => {
    loadcatagory();
  }, []);

  const loadcatagory = () => {
    setLoading(true);
    var data = {
      token: token,
      adminid: adminid,
    };

    backend.catagorieslist(data).then((r) => {
      let arrayObj = r.data;
      arrayObj = arrayObj.map((item) => {
        return {
          value: item.cat_id,
          label: item.catagory_name,
        };
      });

      if (r.error == "False") {
        setCategory_list(arrayObj);
        setLoading(false);
      }
    });
  };

  const _edit_product = () => {
    let data = {
      token: token,
      adminid: adminid,
      id: props.data.id,
      amount: count > 0 ? count : 0,
    };
    setLoading(true);
    backend.update_recipe(data).then((r2) => {
      setLoading(false);
      if (r2.error === "False") {
        props.close();
      } else {
        alert.show(r2.message);
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <div
        style={{
          height: "50%",
          width: "40%",
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          display: "flex",
          alignSelf: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 20,
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: Main_Color,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>Add Recipe Item Amount</div>
          <div onClick={() => props.close()}>
            <AiFillCloseCircle style={{ height: "40px", width: "40px" }} />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span
                className="p-float-label"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <InputText
                  placeholder="Enter Amount Of Recipe Item Used"
                  value={name}
                  keyfilter="num"
                  style={{
                    width: "100%",
                    marginTop: 10,
                    borderWidth: alert_type === 1 ? 2 : 1,
                    borderColor: alert_type === 1 ? Main_Color : "",
                  }}
                  onChange={(e) => setCount(e.target.value)}
                />
                {alert_type === 1 ? (
                  <div
                    style={{ marginTop: 5, paddingLeft: 10, color: Main_Color }}
                  >
                    {"Invalid Recipe Amount "}
                  </div>
                ) : null}
              </span>
            </div>

            {loading === true ? (
              <div
                style={{
                  alignSelf: "center",
                  marginTop: 40,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                <ReactLoading
                  type={"bars"}
                  color={"#fff"}
                  height={45}
                  width={45}
                />
              </div>
            ) : (
              <div
                onClick={_edit_product}
                style={{
                  alignSelf: "center",
                  marginTop: 40,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                Submit
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
