import React, { Component } from "react";
import { BsPlusSquareFill } from "react-icons/bs";
import { FcManager } from "react-icons/fc";
import { Main_Color } from "../../Theme/Theme";
import Backend from "../../Backend/Backend";
import { useAlert } from "react-alert";
import Modal from "@mui/material/Modal";
import TableCard from "./TableCard";
import Add from "./Add/Add";
import Edit from "./Edit/Edit";
import hotkeys from "hotkeys-js";

const backend = new Backend();
const Table = () => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = React.useState(localStorage.getItem("adminid"));
  const [edit_modal, setEdit_modal] = React.useState(false);
  const [editdata, setEditData] = React.useState("");
  const alert = useAlert();

  React.useEffect(() => {
    hotkeys("esc", async (event, handler) => {
      event.preventDefault();
      handleClose();
      handleClose_edit();
    });
    loadconent();
  }, []);

  const loadconent = () => {
    setLoading(true);
    var data = {
      token: token,
      adminid: adminid,
    };
    backend.tableslist(data).then((r) => {
      if (r.error === "False") {
        setData(r.data);
        setLoading(false);
      } else {
        alert.error("You just broke something!");
        setLoading(false);
      }
    });
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleOpen_edit = (i) => {
    setEditData(i);
    setEdit_modal(true);
  };

  const handleClose_edit = () => {
    setEdit_modal(false);
    setEditData("");
  };
  return (
    <div style={{ flex: 1, backgroundColor: "#f2f2f2" }}>
      <div
        style={{
          display: "flex",
          height: "100px",
          backgroundColor: "#ffffff",
          flex: 1,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: 20,
            color: Main_Color,
            fontSize: 22,
            paddingLeft: 40,
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <FcManager style={{ fontSize: 55 }} />
          <div style={{ paddingLeft: 20, fontSize: 22 }}>Table</div>
        </div>
        <div
          onClick={handleOpen}
          style={{
            display: "flex",
            padding: 20,
            color: Main_Color,
            fontSize: 22,
            paddingRight: 40,
          }}
        >
          <BsPlusSquareFill style={{ fontSize: 40 }} />
        </div>
      </div>

      <div style={{ padding: 10 }}>
        <div
          style={{
            flex: 1,
            height: "83vh",
            borderRadius: 10,
            overflowY: "scroll",
          }}
        >
          {loading === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#ffffff",
                height: "100%",
              }}
            >
              <img src={require("../../Image/loading.gif")} />
            </div>
          ) : data.length > 0 ? (
            data.map((i, t) => {
              return (
                <TableCard
                  item={i}
                  index={t}
                  edit_modal={(i) => handleOpen_edit(i)}
                  loadNew={() => loadconent()}
                />
              );
            })
          ) : (
            <div
              style={{
                flex: 1,
                marginTop: 10,
                backgroundColor: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                borderRadius: 10,
              }}
            >
              <img
                src={require("../../Image/noData.gif")}
                style={{ height: "50vh" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "bold", color: "gray" }}>
                  NO DATA FOUND
                </div>
                <div
                  onClick={() => loadconent()}
                  style={{
                    fontWeight: "bold",
                    color: "gray",
                    marginTop: 20,
                    padding: 15,
                    backgroundColor: Main_Color,
                    borderRadius: 10,
                    color: "#fff",
                  }}
                >
                  RELOAD
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Add
          close={() => {
            loadconent();
            handleClose(false);
          }}
        />
      </Modal>
      <Modal
        open={edit_modal}
        onClose={handleClose_edit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Edit
          close={() => {
            loadconent();
            handleClose_edit(false);
          }}
          data={editdata}
        />
      </Modal>
    </div>
  );
};

export default Table;
