import { Checkbox } from "primereact/checkbox";
import React, { Component } from "react";

export default class RecipeScreenComp extends Component {
  constructor() {
    super();
    this.state = {
      status_merge: false,
    };
  }
  render() {
    return (
      <div
        style={{
          padding: 10,
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 5,
        }}
      >
        <div style={{ flex: 1, fontSize: 18 }}>{this.props.data.name}</div>
        <div>
          <Checkbox
            checked={this.state.status_merge}
            onChange={(e) => {
              this.setState({ status_merge: !this.state.status_merge });
              if (e.checked == true) {
                this.props.add(this.props.data.id);
              } else {
                this.props.remove(this.props.data.id);
              }
            }}
          ></Checkbox>
        </div>
      </div>
    );
  }
}
