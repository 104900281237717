import React, { Component, useState } from "react";
import "./Login.css";
import logos from "../../Image/Photo/logo.png";
import { InputText } from "primereact/inputtext";
import ReactLoading from "react-loading";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Backend from "../../Backend/Backend";
import { useNavigate } from "react-router-dom";
import { Main_Color } from "../../Theme/Theme";

const backend = new Backend();

function Login() {
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  const _onLogin = () => {
    if (mobile == "") {
      var x = document.getElementsByClassName("mobile")[0]; //Get the first element with the class name
      x.style.borderColor = "red";
      setTimeout(() => {
        var x = document.getElementsByClassName("mobile")[0]; //Get the first element with the class name
        x.style.borderColor = "";
      }, 3000);
    } else if (password == "") {
      var x = document.getElementsByClassName("password")[0]; //Get the first element with the class name
      x.style.borderColor = "red";
      setTimeout(() => {
        var x = document.getElementsByClassName("password")[0]; //Get the first element with the class name
        x.style.borderColor = "";
      }, 3000);
    } else {
      setLoading(true);
      var data = {
        mobile: mobile,
        password: password,
      };
      backend.login(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          localStorage.setItem("token", r.auth);
          localStorage.setItem("isLogged", "1");
          localStorage.setItem("adminid", r.data.id);
          localStorage.setItem("admindata", JSON.stringify(r.data));
          window.location = "/";
        } else {
          alert(r.message);
        }
      });
    }
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            flex: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background:
              "radial-gradient(circle,  rgba(230, 46, 79, 1) 0%, rgba(230, 46, 79, 1) 50%, rgba(230, 46, 79, 1) 100%)",
          }}
        >
          <img src={logos} style={{ width: "70%" }} />
        </div>
        <div
          style={{
            flex: 1.5,
            backgroundColor: "#ffffff",
            borderStyle: "solid",
            borderWidth: 0,
            height: "100vh",
            borderLeftWidth: 1,
            borderLeftColor: "rgba(0,0,0,0.2)",
            display: "flex",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ flex: 1 }}>
                <img
                  src={require("../../Image/logo.png")}
                  style={{
                    height: "90px",
                    width: "90px%",
                  }}
                />
              </div>
              <div
                style={{
                  flex: 1,
                  padding: 50,
                  paddingTop: 40,
                  display: "flex",
                  width: "80%",
                  flexDirection: "column",
                }}
              >
                <text style={{ fontSize: 27, color: Main_Color }}>Sign In</text>
                <text style={{ fontSize: 15, marginTop: 30, color: "#9f9e9f" }}>
                  WELCOME BACK TO YOUR ACCOUNT
                </text>
              </div>
            </div>

            <div style={{ flex: 1, padding: 30, paddingTop: 0 }}>
              <label>Mobile</label>
              <div
                className="p-inputgroup"
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <InputText
                  className="mobile"
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                  maxLength={10}
                  value={mobile}
                  placeholder="mobile"
                />
              </div>
              <label>Password</label>
              <div
                className="p-inputgroup"
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <InputText
                  className="password"
                  type={"password"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  maxLength={256}
                  value={password}
                  placeholder="Password"
                />
              </div>
            </div>
            <div
              style={{
                flex: 1,
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {loading ? (
                <div
                  style={{
                    height: "55px",
                    width: "200px",
                    backgroundColor: Main_Color,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 30,
                  }}
                >
                  <ReactLoading
                    type={"bars"}
                    color={"#fff"}
                    height={45}
                    width={45}
                  />
                </div>
              ) : (
                <div
                  onClick={() => _onLogin()}
                  style={{
                    height: "55px",
                    width: "200px",
                    backgroundColor: Main_Color,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 30,
                  }}
                >
                  <text style={{ color: "#fff", fontWeight: "bold" }}>
                    Login Now
                  </text>
                </div>
              )}

              <div style={{ paddingTop: 30 }}>
                Don't have account ?{" "}
                <text style={{ fontSize: 22, fontWeight: "bold" }}>
                  Sign Up
                </text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
