import React, { Component } from "react";
import ReactDragListView from "react-drag-listview";
import { CgArrowsExchangeV } from "react-icons/cg";
import { Main_Color } from "../../Theme/Theme";

export default class ProductCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data2: [],
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        data: this.props.product,
      });
    }, 300);
  }

  render() {
    var newdata = this.state.data;
    var newvar = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const item = newdata.splice(fromIndex, 1)[0];
        newdata.splice(toIndex, 0, item);
        newdata.map((i, t) => {
          newdata[t].newserial = t + 1;
        });

        newvar.setState({ data: newdata });
        newvar.props.Update_Sequence(newdata, "Product");
      },
      nodeSelector: "li",
      handleSelector: "a",
    };

    return (
      <div
        style={{
          padding: "20px",
          paddingTop: "10px",
        }}
      >
        <ReactDragListView {...dragProps}>
          {this.state.data.map((item, index) => {
            return (
              <li
                key={item.menu_sr}
                style={{
                  display: "flex",
                  height: "45px",
                  backgroundColor: "#f2f2f2",
                  borderRadius: 10,
                  marginTop: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ flex: 1, paddingLeft: "20px" }}>
                  {item.menu_sr} - {item.product_name}
                </div>
                <a style={{ paddingRight: "20px" }}>
                  <CgArrowsExchangeV
                    style={{ fontSize: 30, color: Main_Color }}
                  />
                </a>
              </li>
            );
          })}
        </ReactDragListView>
      </div>
    );
  }
}
