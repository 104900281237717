import React, { useEffect, useState } from "react";
import { Main_Color } from "../../Theme/Theme";
import { AiFillSave } from "react-icons/ai";
import { FcManager } from "react-icons/fc";
import { useAlert } from "react-alert";
import CategoriesCard from "./CategoriesCard";
import ProductCard from "./ProductCard";
import TableCard from "./TableCard";
import Backend from "../../Backend/Backend";
const backend = new Backend();

export default function Sequence() {
  const alert = useAlert();

  const [selecter, setSelecter] = useState(0);
  const [categories, setCategories] = useState(0);
  const [product, setProduct] = useState(0);
  const [table, setTable] = useState(0);
  const [newroute, setNewRoute] = useState(0);
  const [listName, setListName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadconent_Categoies();
    loadconent_Product();
    loadconent_Table();
  }, []);

  const loadconent_Categoies = () => {
    var data = {
      token: localStorage.getItem("token"),
      adminid: localStorage.getItem("adminid"),
    };
    backend.catagorieslist(data).then((r) => {
      if (r.error === "False") {
        setCategories(r.data);
      } else {
        alert.error(r.massage);
      }
    });
  };

  const loadconent_Table = () => {
    var data = {
      token: localStorage.getItem("token"),
      adminid: localStorage.getItem("adminid"),
    };
    backend.tableslist(data).then((r) => {
      if (r.error === "False") {
        setTable(r.data);
      } else {
        alert.error(r.massage);
      }
    });
  };

  const loadconent_Product = () => {
    var data = {
      token: localStorage.getItem("token"),
      adminid: localStorage.getItem("adminid"),
    };
    backend.productlist(data).then((r) => {
      if (r.error === "False") {
        setProduct(r.data);
      } else {
        alert.error(r.massage);
      }
    });
  };

  const Update_Sequence = (a, b) => {
    setNewRoute(a);
    setListName(b);
  };

  const onUpdateRoute = () => {
    if (listName == "Categories") {
      setLoading(true);
      var data = {
        token: localStorage.getItem("token"),
        adminid: localStorage.getItem("adminid"),
        newdata: newroute,
      };
      backend.new_sequencer_categories(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          loadconent_Categoies();
          loadconent_Product();
          loadconent_Table();
          alert.error(r.message);
        } else {
          alert.error(r.message);
        }
      });
    } else if (listName == "Product") {
      setLoading(true);
      var data = {
        token: localStorage.getItem("token"),
        adminid: localStorage.getItem("adminid"),
        newdata: newroute,
      };
      backend.new_sequencer_product(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          loadconent_Categoies();
          loadconent_Product();
          loadconent_Table();
          alert.error(r.message);
        } else {
          alert.error(r.message);
        }
      });
    } else if (listName == "Table") {
      setLoading(true);
      var data = {
        token: localStorage.getItem("token"),
        adminid: localStorage.getItem("adminid"),
        newdata: newroute,
      };
      backend.new_sequencer_table(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          loadconent_Categoies();
          loadconent_Product();
          loadconent_Table();
          alert.error(r.message);
        } else {
          alert.error(r.message);
        }
      });
    }
  };

  return (
    <div style={{ flex: 1, backgroundColor: "#f2f2f2" }}>
      <div
        style={{
          display: "flex",
          height: "100px",
          backgroundColor: "#ffffff",
          flex: 1,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: 20,
            color: Main_Color,
            fontSize: 22,
            paddingLeft: 40,
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <FcManager style={{ fontSize: 55 }} />
          <div style={{ paddingLeft: 20, fontSize: 22, flex: 1 }}>Sequence</div>
        </div>
        <div
          onClick={() => (loading ? null : onUpdateRoute())}
          style={{
            display: "flex",
            padding: 20,
            color: Main_Color,
            fontSize: 22,
            paddingRight: 40,
          }}
        >
          <AiFillSave style={{ fontSize: 40 }} />
        </div>
      </div>
      <div style={{ padding: 10, display: "flex", flex: 1 }}>
        <div
          style={{
            backgroundColor: "#ffffff",
            flex: 0.4,
            height: "80vh",
            padding: 10,
            margin: 10,
            borderRadius: 10,
          }}
        >
          <div
            onClick={() => setSelecter(1)}
            style={{
              padding: 15,
              backgroundColor: selecter == 1 ? Main_Color : "#f2f2f2",
              borderRadius: 10,
              marginBottom: 10,
              color: selecter == 1 ? "#ffffff" : "#585858",
            }}
          >
            Categories
          </div>
          <div
            onClick={() => setSelecter(2)}
            style={{
              padding: 15,
              backgroundColor: selecter == 2 ? Main_Color : "#f2f2f2",
              borderRadius: 10,
              marginBottom: 10,
              color: selecter == 2 ? "#ffffff" : "#585858",
            }}
          >
            Product
          </div>
          <div
            onClick={() => setSelecter(3)}
            style={{
              padding: 15,
              backgroundColor: selecter == 3 ? Main_Color : "#f2f2f2",
              borderRadius: 10,
              marginBottom: 10,
              color: selecter == 3 ? "#ffffff" : "#585858",
            }}
          >
            Table
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#ffffff",
            flex: 1,
            height: "80vh",
            margin: 10,
            borderRadius: 10,
            overflowY: "scroll",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 18,
              }}
            >
              <img src={require("../../Image/loading.gif")} />
            </div>
          ) : selecter == 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 18,
              }}
            >
              Select Item First
            </div>
          ) : selecter == 1 ? (
            <CategoriesCard
              categories={categories}
              Update_Sequence={(a, b) => Update_Sequence(a, b)}
            />
          ) : selecter == 2 ? (
            <ProductCard
              product={product}
              Update_Sequence={(a, b) => Update_Sequence(a, b)}
            />
          ) : selecter == 3 ? (
            <TableCard
              table={table}
              Update_Sequence={(a, b) => Update_Sequence(a, b)}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
