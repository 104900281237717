import React, { Component } from "react";
import { Main_Color } from "../../Theme/Theme";
import { BiLogOut } from "react-icons/bi";
import Account from "./Account";
import Password from "./Password";
import Gst from "./Gst";
import Addess from "./Addess";
import Download from "./Download";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import Print from "./Print";

export default class Profile extends Component {
  constructor() {
    super();
    this.state = {
      admindata: JSON.parse(localStorage.getItem("admindata")),
      active: 1,
      status: "",
    };
  }

  componentDidMount() {
    window.addEventListener("online", this.changeStatus);
    window.addEventListener("offline", this.changeStatus);
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.changeStatus);
    window.removeEventListener("offline", this.changeStatus);
  }

  changeStatus() {
    console.log(navigator.onLine);
  }

  Logout() {
    confirmAlert({
      title: "Confirm to Logout",
      message: "Are you sure logout.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            localStorage.clear();
            window.location = "/";
          },
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  }

  render() {
    return (
      <div style={{ display: "flex", flex: 1 }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 3,
            borderRightColor: "#f2f2f2",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: "140px",
                  width: "140px",
                  borderRadius: 100,
                }}
              >
                <img
                  style={{
                    height: "140px",
                    width: "140px",
                    borderRadius: 100,
                  }}
                  src={require("../../Image/logo.png")}
                />
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 22,
                color: "#000",
              }}
            >
              {this.state.admindata == null
                ? ""
                : this.state.admindata.username}
            </div>
          </div>
          <div style={{ flex: 2 }}>
            <div
              onClick={() =>
                this.setState({
                  active: 1,
                })
              }
              style={{
                height: "55px",
                display: "flex",
                alignItems: "center",
                paddingLeft: 20,
                backgroundColor:
                  this.state.active == 1 ? Main_Color : "#f2f2f2",
                borderStyle: "solid",
                borderWidth: 0,
                borderBottomWidth: 1,
                borderBottomColor: "#ffffff",
                color: this.state.active == 1 ? "#ffffff" : "#000000",
              }}
            >
              Account
            </div>
            <div
              onClick={() =>
                this.setState({
                  active: 2,
                })
              }
              style={{
                height: "55px",
                display: "flex",
                alignItems: "center",
                paddingLeft: 20,
                backgroundColor:
                  this.state.active == 2 ? Main_Color : "#f2f2f2",
                borderStyle: "solid",
                borderWidth: 0,
                borderBottomWidth: 1,
                borderBottomColor: "#ffffff",
                color: this.state.active == 2 ? "#ffffff" : "#000000",
              }}
            >
              Password
            </div>
            <div
              onClick={() =>
                this.setState({
                  active: 3,
                })
              }
              style={{
                height: "55px",
                display: "flex",
                alignItems: "center",
                paddingLeft: 20,
                backgroundColor:
                  this.state.active == 3 ? Main_Color : "#f2f2f2",
                borderStyle: "solid",
                borderWidth: 0,
                borderBottomWidth: 1,
                borderBottomColor: "#ffffff",
                color: this.state.active == 3 ? "#ffffff" : "#000000",
              }}
            >
              Gst
            </div>
            <div
              onClick={() =>
                this.setState({
                  active: 4,
                })
              }
              style={{
                height: "55px",
                display: "flex",
                alignItems: "center",
                paddingLeft: 20,
                backgroundColor:
                  this.state.active == 4 ? Main_Color : "#f2f2f2",
                borderStyle: "solid",
                borderWidth: 0,
                borderBottomWidth: 1,
                borderBottomColor: "#ffffff",
                color: this.state.active == 4 ? "#ffffff" : "#000000",
              }}
            >
              Address
            </div>

            <div
              onClick={() =>
                this.setState({
                  active: 5,
                })
              }
              style={{
                height: "55px",
                display: "flex",
                alignItems: "center",
                paddingLeft: 20,
                backgroundColor:
                  this.state.active == 5 ? Main_Color : "#f2f2f2",
                borderStyle: "solid",
                borderWidth: 0,
                borderBottomWidth: 1,
                borderBottomColor: "#ffffff",
                color: this.state.active == 5 ? "#ffffff" : "#000000",
              }}
            >
              Download
            </div>
            <div
              onClick={() =>
                this.setState({
                  active: 6,
                })
              }
              style={{
                height: "55px",
                display: "flex",
                alignItems: "center",
                paddingLeft: 20,
                backgroundColor:
                  this.state.active == 6 ? Main_Color : "#f2f2f2",
                borderStyle: "solid",
                borderWidth: 0,
                borderBottomWidth: 1,
                borderBottomColor: "#ffffff",
                color: this.state.active == 6 ? "#ffffff" : "#000000",
              }}
            >
              Print
            </div>

            <div
              onClick={() => this.Logout()}
              style={{
                height: "55px",
                display: "flex",
                alignItems: "center",
                paddingLeft: 20,
                backgroundColor: "#f2f2f2",
                borderStyle: "solid",
                borderWidth: 0,
                borderBottomWidth: 1,
                borderBottomColor: "#ffffff",
                color: "#000000",
              }}
            >
              <div>
                <BiLogOut
                  style={{
                    color: Main_Color,
                    fontSize: 40,
                    paddingRight: "10px",
                  }}
                />
              </div>
              <div>Logout</div>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 3,
            padding: 10,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {this.state.active == 1 ? (
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Account />
            </div>
          ) : null}
          {this.state.active == 2 ? (
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Password />
            </div>
          ) : null}
          {this.state.active == 3 ? (
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Gst />
            </div>
          ) : null}
          {this.state.active == 4 ? (
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Addess />
            </div>
          ) : null}
          {this.state.active == 5 ? (
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Download />
            </div>
          ) : null}
          {this.state.active == 6 ? (
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Print />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
