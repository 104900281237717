import React, { useState } from "react";
import "./Edit.css";
import { InputText } from "primereact/inputtext";
import { Main_Color } from "./../../../Theme/Theme";
import { AiFillCloseCircle } from "react-icons/ai";
import Backend from "../../../Backend/Backend";
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";
import { Checkbox } from "primereact/checkbox";
const backend = new Backend();
const Edit = (props) => {
  const [tablename, setTableName] = useState(props.data.table_name);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = useState(localStorage.getItem("adminid"));
  const [alert_type, setAlert_type] = useState(localStorage.getItem("adminid"));
  const [loading, setLoading] = useState(false);
  const [accountState, setAccountState] = useState(
    props.data.ac_nonac === "1" ? true : false
  );

  const alert = useAlert();

  const _edit_table = () => {
    if (tablename === "") {
      setAlert_type(1);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else {
      let data = {
        table_name: tablename,
        token: token,
        adminid: adminid,
        id: props.data.id,
        ac: accountState,
      };
      setLoading(true);
      backend.edittables(data).then((r2) => {
        setLoading(false);
        if (r2.error === "False") {
          props.close();
        } else {
          alert.show(r2.message);
        }
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <div
        style={{
          height: "50%",
          width: "40%",
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          display: "flex",
          alignSelf: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 20,
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: Main_Color,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>Edit Table</div>
          <div onClick={() => props.close()}>
            <AiFillCloseCircle style={{ height: "40px", width: "40px" }} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span
                className="p-float-label"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <InputText
                  placeholder="Table Name "
                  style={{
                    width: "500px",
                    marginTop: 10,
                    borderWidth: alert_type === 1 ? 2 : 1,
                    borderColor: alert_type === 1 ? Main_Color : "",
                  }}
                  value={tablename}
                  onChange={(e) => setTableName(e.target.value)}
                />
                {alert_type === 1 ? (
                  <div
                    style={{ marginTop: 5, paddingLeft: 10, color: Main_Color }}
                  >
                    {"Invalid Table Name "}
                  </div>
                ) : null}
              </span>
            </div>
            <div
              style={{
                padding: 10,
                justifyContent: "flex-start",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
                alignItems: "center",
                paddingLeft: 40,
              }}
            >
              <Checkbox
                onChange={(e) => setAccountState(e.checked)}
                checked={accountState}
              ></Checkbox>
              <div
                style={{
                  color: accountState === true ? "green" : "red",
                  paddingLeft: 15,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                {accountState == true ? "Ac" : "Non Ac"}
              </div>
            </div>
            {loading === true ? (
              <div
                style={{
                  alignSelf: "center",
                  marginTop: 40,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                <ReactLoading
                  type={"bars"}
                  color={"#fff"}
                  height={45}
                  width={45}
                />
              </div>
            ) : (
              <div
                onClick={_edit_table}
                style={{
                  alignSelf: "center",
                  marginTop: 40,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                Submit
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
