import React from "react";
import { BsFillFileEarmarkPlusFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { Main_Color } from "../../Theme/Theme";
import { AiFillDelete } from "react-icons/ai";
import Backend from "../../Backend/Backend";
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";

const backend = new Backend();
const RawItemsCard = (props) => {
  const i = props.item;
  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);

  const delete_rawitem = () => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoading(true);
            var data = {
              token: localStorage.getItem("token"),
              adminid: localStorage.getItem("adminid"),
            };

            backend.deleterawitem(i.id, data).then((r) => {
              setLoading(false);
              if (r.error === "False") {
                alert.success(r.message);
                props.loadNew();
              } else {
                alert.error(r.message);
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  };
  return (
    <div key={i.id}>
      <div
        style={{
          display: "flex",
          flex: 1,
          backgroundColor: "#ffffff",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 10,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div style={{ paddingLeft: 20, display: "flex", alignItems: "center" }}>
          <div style={{ paddingRight: 20, color: "#585858" }}>
            {props.index + 1}
          </div>
          <div>
            <div style={{ color: "#000000" }}>{i.name}</div>
            <div style={{ color: "#000000" }}>{i.stock}</div>
          </div>
        </div>
        <div
          style={{
            marginRight: 40,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => props.edit_modal(i)}
            style={{
              height: "40px",
              width: "55px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FiEdit style={{ fontSize: 30, color: "green" }} />
          </div>
          <div
            onClick={() => props.stock(i)}
            style={{
              height: "40px",
              width: "55px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BsFillFileEarmarkPlusFill
              style={{ fontSize: 30, color: Main_Color }}
            />
          </div>

          {loading === true ? (
            <div
              style={{
                height: "40px",
                width: "55px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReactLoading
                type={"spin"}
                color={Main_Color}
                height={30}
                width={30}
              />
            </div>
          ) : (
            <div
              onClick={delete_rawitem}
              style={{
                height: "40px",
                width: "55px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AiFillDelete style={{ fontSize: 35, color: "red" }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RawItemsCard;
